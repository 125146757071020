import { useState } from "preact/hooks";
import PortcallVoyageGroup from "../../components/portcall/PortcallVoyageGroup";
import {Box, Button} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoopIcon from '@mui/icons-material/Loop';

const ArchiveContainer = ({
  archivePortcalls,
  archiveTotalCount,
  getArchivedPortcalls,
  isLoading,
  baseUrlToMainApp,
  setArchivePortcalls,
  showSuccessToast,
  showFailedToast,
  closeToast,
}) => {
  const [isAnyPortcallInEditMode, setIsAnyPortcallInEditMode] = useState(false);
  const isAbleToLoadMorePortcalls = archiveTotalCount > archivePortcalls.length;

  return (
    <>
      {archivePortcalls?.map((groupArr, groupIdx) => (
        <PortcallVoyageGroup
          key={groupArr[0]?.portCallId}
          groupIdx={groupIdx}
          groupArr={groupArr}
          portcalls={archivePortcalls}
          baseUrlToMainApp={baseUrlToMainApp}
          setArchivePortcalls={setArchivePortcalls}
          isAnyPortcallInEditMode={isAnyPortcallInEditMode}
          setIsAnyPortcallInEditMode={setIsAnyPortcallInEditMode}
          showSuccessToast={showSuccessToast}
          showFailedToast={showFailedToast}
          closeToast={closeToast}
          archivedPortcall
          isScheduled={false}
        />
      ))}

      {isAbleToLoadMorePortcalls && (
          <Stack justifyContent='center' alignItems='center' sx={{my: '18px', padding: '0 20px 0 30px'}}>
            {isLoading ? (
                <Box width={131}>
                  <Button
                      variant='contained'
                      sx={{borderRadius: '6px'}}
                  >
                    <LoopIcon sx={{          animation: "spin 2s linear infinite",
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "100%": {
                          transform: "rotate(-360deg)",
                        },
                      }, marginRight: '4px'}} /> Load More
                  </Button>
                </Box>
            ) : (
                <Box width={113}>
                  <Button
                      variant='contained'
                      onClick={getArchivedPortcalls}
                      disabled={isLoading}
                      sx={{borderRadius: '6px'}}
                  >
                    Load More
                  </Button>
                </Box>
            )}

          </Stack>
      )}
    </>
  );
};

export { ArchiveContainer };

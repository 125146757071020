import { lightColors } from "../../utils/lightColors";
import { darkColors } from "../../utils/darkColors";
import { alpha } from "@mui/material/styles";

export const autocompletePalette = (mode) => {
  if (mode === "dark") {
    return {
      color: darkColors.colors.white,
      background: darkColors.dark["main-25"],
      hover: {
        backgroundColor: alpha("#B7E8E7", 0.5),
        color: `${darkColors.dark.main} !important`,
      },
    };
  }
  return {
    color: lightColors.dark.main,
    background: lightColors.colors.white,
    hover: {
      color: lightColors.dark.main,
      background: lightColors.colors.lightGray,
    },
  };
};

export const autocompleteConfig = {
  styleOverrides: {
    listbox: {
      padding: "0 !important",
    },
    option: ({ theme }) => ({
      color: theme.palette.components.autocomplete.color,
      backgroundColor: theme.palette.components.autocomplete.background,
      border: "none",
      "&:hover": {
        color: theme.palette.components.autocomplete.hover.color,
        backgroundColor: theme.palette.components.autocomplete.hover.background,
      },
      "&.Mui-focused": {
        color: theme.palette.components.autocomplete.hover.color,
        backgroundColor: theme.palette.components.autocomplete.hover.background,
      },
    }),
  },
};

import {
  billOfLadingStatusEnums,
  NO_BILL_OF_LADING_STATUS,
} from "../../utils/constants";
import {
  bcDateTimeUTCFormat,
  NEW_DATE_FORMAT,
} from "../../../../utils/helpers/dateTimePickerHelpers";
import dayjs from "dayjs";
import { BcSelect } from "../../../globalComponents/select/BcSelect";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { WatchLater } from "@mui/icons-material";
import { BcDateTimePicker } from "../../../datepicker/BcDateTimePicker";

const LoadingDischargingSectionMui = ({
  editMode,
  cargoes,
  setBillOfLadingStatus,
  isLoading,
}) => {
  const loadingCargoes = cargoes.filter(
    ({ activityType }) => activityType === 1000
  );
  const dischargingCargoes = cargoes.filter(
    ({ activityType }) => activityType === 2000
  );
  const generateBillOfLaydingStatusLabel = (billOfLadingStatus) => {
    return (
      billOfLadingStatusEnums?.find(({ value }) => value === billOfLadingStatus)
        ?.label ?? NO_BILL_OF_LADING_STATUS
    );
  };

  return (
    <>
      <Stack alignItems="start" mb="14px" mt="20px">
        <Stack>
          <Typography variant="h2">Discharging</Typography>
          {dischargingCargoes?.length ? (
            dischargingCargoes.map((cargo, index) => {
              const { billOfLadingStatus, cargoName, portCallCargoId } = cargo;
              return (
                <Stack mb="20px" key={index}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt={!index ? "16px" : "20px"}
                    key={index}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 14 14"
                      fill="none"
                      style={{
                        transform: "rotate(180deg)",
                      }}
                      sx={{
                        "& path": {
                          width: "20px",
                          height: "20px",
                        },
                      }}
                    >
                      <path d="M0 7L7 14L14 7V0L0 0L0 7Z" fill="#24BAB6" />
                    </SvgIcon>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        background: (theme) =>
                          theme.palette.components.chip.default.background,
                        px: "6px",
                        py: "4.5px",
                        ml: "10px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          color: (theme) =>
                            theme.palette.components.chip.default.color,
                          fontWeight: "500 !important",
                          background: (theme) =>
                            theme.palette.components.chip.default.background,
                        }}
                      >
                        {cargoName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" mt="8px">
                    {!editMode && (
                      <>
                        {generateBillOfLaydingStatusLabel(
                          billOfLadingStatus
                        ) === NO_BILL_OF_LADING_STATUS ? (
                          <Box
                            sx={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "100%",
                              background: "#F14549",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.78033 0.333374C7.13366 0.333374 7.47366 0.473374 7.72699 0.726707L10.9403 3.94671C11.1937 4.19337 11.3337 4.53337 11.3337 4.88671V12.3334C11.3337 13.0667 10.7337 13.6667 10.0003 13.6667H1.99366C1.26033 13.6667 0.666992 13.0667 0.666992 12.3334V1.66671C0.666992 0.933374 1.26699 0.333374 2.00033 0.333374H6.78033ZM6.66699 1.33337V4.33337C6.66699 4.70004 6.96699 5.00004 7.33366 5.00004H10.3337L6.66699 1.33337ZM6.94313 10.8857L6.00033 9.94293L5.05752 10.8857C4.79824 11.145 4.37398 11.145 4.11471 10.8857C3.85543 10.6265 3.85544 10.2022 4.11471 9.94293L5.05752 9.00012L4.11471 8.05731C3.85544 7.79804 3.85543 7.37377 4.11471 7.1145C4.37398 6.85523 4.79824 6.85523 5.05752 7.1145L6.00033 8.05731L6.94313 7.1145C7.20241 6.85523 7.62667 6.85523 7.88594 7.1145C8.14522 7.37377 8.14522 7.79804 7.88594 8.05731L6.94313 9.00012L7.88594 9.94293C8.14522 10.2022 8.14522 10.6265 7.88594 10.8857C7.62667 11.145 7.20241 11.145 6.94313 10.8857Z"
                                fill="white"
                              />
                            </svg>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "100%",
                              background: "#1BC641",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.72699 0.726707C7.47366 0.473374 7.13366 0.333374 6.78033 0.333374H2.00033C1.26699 0.333374 0.666992 0.933374 0.666992 1.66671V12.3334C0.666992 13.0667 1.26033 13.6667 1.99366 13.6667H10.0003C10.7337 13.6667 11.3337 13.0667 11.3337 12.3334V4.88671C11.3337 4.53337 11.1937 4.19337 10.9403 3.94671L7.72699 0.726707ZM6.66699 4.33337V1.33337L10.3337 5.00004H7.33366C6.96699 5.00004 6.66699 4.70004 6.66699 4.33337Z"
                                fill="white"
                              />
                            </svg>
                          </Box>
                        )}
                      </>
                    )}
                    {editMode ? (
                      <BcSelect
                        value={billOfLadingStatus}
                        options={billOfLadingStatusEnums}
                        onChange={(newValue) => {
                          setBillOfLadingStatus(portCallCargoId, newValue);
                        }}
                        label="Document type"
                        isLoading={isLoading}
                        sx={{
                          width: "429px",
                          mt: "10px",
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        ml="10px"
                        mt="3px"
                        fontSize="14px"
                      >
                        {generateBillOfLaydingStatusLabel(billOfLadingStatus)}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              );
            })
          ) : (
            <Typography variant="body2" mt="16px">
              No discharging cargoes
            </Typography>
          )}
        </Stack>
        <Stack mt="30px">
          <Typography variant="h2">Loading</Typography>
          {loadingCargoes?.length ? (
            loadingCargoes.map((cargo, index) => {
              const { cargoName, laycanStart, laycanEnd } = cargo;
              return (
                <Stack key={index} mb="20px">
                  <Box
                    display="flex"
                    flexDirection="row"
                    mt={!index ? "16px" : "20px"}
                    key={index}
                  >
                    <SvgIcon
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 14 14"
                      fill="none"
                      style={{
                        marginTop: "2px",
                      }}
                    >
                      <path d="M0 7L7 14L14 7V0L0 0L0 7Z" fill="#24BAB6" />
                    </SvgIcon>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        background: (theme) =>
                          theme.palette.components.chip.default.background,
                        px: "6px",
                        py: "4.5px",
                        fontWeight: 400,
                        ml: "10px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          color: (theme) =>
                            theme.palette.components.chip.default.color,
                          fontWeight: "500 !important",
                          marginTop: "2px",
                        }}
                      >
                        {cargoName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" mt="8px">
                    <>
                      <WatchLater
                        sx={{
                          width: "24px",
                          height: "24px",
                          fill: (theme) =>
                            theme.palette.components.icons.time.background,
                        }}
                      />
                      <Typography
                        variant="body2"
                        ml="10px"
                        mt="3px"
                        fontSize="14px"
                      >
                        {bcDateTimeUTCFormat(
                          laycanStart ? dayjs(laycanStart).utc() : null,
                          NEW_DATE_FORMAT,
                          "Not provided"
                        )}{" "}
                        -{" "}
                        {bcDateTimeUTCFormat(
                          laycanEnd ? dayjs(laycanEnd).utc() : null,
                          NEW_DATE_FORMAT,
                          "Not provided"
                        )}
                      </Typography>
                    </>
                  </Box>
                </Stack>
              );
            })
          ) : (
            <Typography variant="body2" mt="16px">
              No loading cargoes
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};
export { LoadingDischargingSectionMui };

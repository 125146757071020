import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from "../../mui/components/alert/Alert";
import useLogin from "./useLogin";
import ThemeToggle from "../../components/globalComponents/ThemeToggle";

const Login = ({ setToken, setChunkToken, setVesselName }) => {
    const {content, errorMessage, clearErrorMessage, logo} = useLogin({ setToken, setChunkToken, setVesselName })
    return (
        <Container fixed sx={{width: '100%', height: '100vh', position: 'relative'}}>
            <Box sx={{ position: 'fixed', left: '50%', transform: 'translateX(-50%)', top: '20px' }}>
                <ThemeToggle />
            </Box>
            <Stack justifyContent='center' alignItems='center' width='100%' height='100%'>
                <Box sx={{ minWidth: '545px', minHeight: '657.55px', border: '1px solid', borderColor: 'primary.main-75', borderRadius: '10px', padding: '50px 51px 55px 51px' }}>
                    <Stack justifyContent='center' alignItems='center' flexDirection='column'>
                        {logo}
                        <Stack sx={{ backgroundColor: "secondary.main", color: "subColors.white", width: '441px', height: '30px', justifyContent: 'center', alignItems: 'center', margin: '23px 0 30px', borderRadius: '5px', fontWeight: 600, fontSize: '16px', lineHeight: '24px', letterSpacing: '5px', textTransform: 'uppercase', userSelect: 'none'}}>On board</Stack>
                        {content}
                    </Stack>

                </Box>
            </Stack>
            <Alert message={errorMessage} onClose={clearErrorMessage} severity="error" />
        </Container>
    )
};

export default Login;


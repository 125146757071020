import { useState } from "preact/hooks";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
const BcCard = ({
  renderHeader,
  renderBody,
  renderFooter,
  cardClassName,
  submitHandler,
  propsRef,
  isActive,
  delaysCount,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <form
      ref={propsRef}
      className={cardClassName}
      onSubmit={(e) => {
        e.preventDefault();
        submitHandler();
      }}
    >
      <Stack mb="25px">
        {/*HEADER*/}
        {renderHeader()}
        {/*BODY*/}
        <Box
          sx={{
            width: "100%",
            borderRight: "1px solid",
            borderLeft: "1px solid",
            borderColor: isActive
              ? "components.card.footer.active.border"
              : "components.card.footer.archive.border",
          }}
        >
          {renderBody()}
        </Box>
        {/*FOOTER*/}
        <Box>
          <Stack
            sx={{
              padding: "8px 12px",
              backgroundColor: isActive
                ? "components.card.footer.active.background"
                : "components.card.footer.archive.background",
              border: "1px solid",
              borderColor: isActive
                ? "components.card.footer.active.border"
                : "components.card.footer.archive.border",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="text"
                onClick={handleCollapse}
                sx={{ color: "components.button.text.secondary.color" }}
              >
                <InsertCommentIcon sx={{ marginRight: "10px" }} /> Remarks &
                Delays {delaysCount ? `(${delaysCount})` : ""}
              </Button>
              <Button variant="text" onClick={handleCollapse}>
                <KeyboardArrowDownIcon
                  sx={{
                    transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </Button>
            </Stack>
            <Collapse in={isCollapsed}>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mt="22px"
              >
                {renderFooter()}
              </Stack>
            </Collapse>
          </Stack>
        </Box>
      </Stack>
    </form>
  );
};

export { BcCard };

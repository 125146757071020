export const iconsPalette = (mode) => {
  if (mode === "dark") {
    return {
      time: {
        color: "#fff",
        background: "#AEE1DE",
      },
    };
  }
  return {
    time: {
      color: "#fff",
      background: "#354776",
    },
  };
};

import {darkColors} from "../../mui/utils/darkColors";
import {lightColors} from "../../mui/utils/lightColors";

export const loginPalette = (mode) => {
    if(mode === "dark") {
        return  {
            title: darkColors.primary['main-15'],
            chekboxLabel: darkColors.colors.white,
            text: darkColors.colors.white
        }
    }

    return {
        title: lightColors.secondary.main,
        chekboxLabel: lightColors.dark.main,
        text: lightColors.dark.main
    }
}
import { useCallback, useEffect, useState } from "preact/hooks";
import { Loader } from "../../components/loader/Loader";
import { ASC } from "../../utils/constants/globalConstants";
import { sortList } from "../../utils/helpers/globalHelpers";
import { ScheduledContainer } from "../../containers/scheduled/ScheduledContainer";
import { useHttp } from "../../hooks/useHttp";
import {
  deleteAllFromScheduledPortcalls,
  addPortcallsToStore,
  getAllFromScheduledPortcalls,
  SCHEDULED_PORTCALLS,
} from "../../db";
import { useServiceWorkerContext } from "../../context/ServiceWorkerContext";
import Alert from "../../mui/components/alert/Alert";
import Stack from "@mui/material/Stack";

const Scheduled = () => {
  const [scheduledPortcalls, setScheduledPortcalls] = useState([]);
  const [baseUrlToMainApp, setBaseUrlToMainApp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState(null);
  const { request } = useHttp();
  const {
    state: { isOnline, count },
  } = useServiceWorkerContext();
  const getScheduledPortcalls = async () => {
    if (isOnline) {
      if (count > 0) return;
      setIsLoading(true)
      request(
        "GetScheduledVoyages",
        "GET",
        null,
        async (data) => {
          setScheduledPortcalls(data?.voyages);
          const sortCache = sortList(data?.voyages);
          await deleteAllFromScheduledPortcalls();
          await addPortcallsToStore(sortCache, SCHEDULED_PORTCALLS);
          setBaseUrlToMainApp(data.baseUrl);
          setIsLoading(false)
        },
        (err) => {
          setIsLoading(false)
          setErrMessage(`${err}; Try to logout/login`);
        }
      );
    } else {
      const cachedData = await getCachePortcalls();
      setScheduledPortcalls(
        sortList(cachedData[0], ASC, ["endOfSeaPassage", "eta"])
      );
    }
  };

  const getCachePortcalls = useCallback(async () => {
    return await getAllFromScheduledPortcalls();
  }, []);

  useEffect(() => {
    getScheduledPortcalls();
  }, [isOnline, count]);

  if (isLoading)
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <Loader displayText />
      </Stack>
    );
  return (
    <div>
      <Alert message={errMessage} severity="error" />
      {scheduledPortcalls.length ? (
        <ScheduledContainer
          key={scheduledPortcalls?.length}
          scheduledPortcalls={scheduledPortcalls}
          setScheduledPortcalls={setScheduledPortcalls}
          baseUrlToMainApp={baseUrlToMainApp}
        />
      ) : (
        "No scheduled portcalls"
      )}
    </div>
  );
};
export default Scheduled;

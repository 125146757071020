import { createContext } from "preact";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "preact/hooks";
import { useServiceWorkerContext } from "./ServiceWorkerContext";
import { useHttp } from "../hooks/useHttp";
import { API_METHODS } from "../utils/constants/api";
import {
  addBunkerFactorsSettingsToStore,
  deleteAllFromBunkerFactorsSettings,
  getAllFromBunkerFactorsSettings,
} from "../db";
import Alert from "../mui/components/alert/Alert";

const GlobalDataContext = createContext({ bunkerFactorSettings: [] });

const GlobalDataContextProvider = ({ children }) => {
  const [bunkerFactorSettings, setBunkerFactorSettings] = useState([]);
  const [errMessage, setErrMessage] = useState(null);
  const { request } = useHttp({ portCallUpdateRelatedRequest: false });

  const {
    state: { isOnline, count },
  } = useServiceWorkerContext();
  const setBunkerFactorsSettings = useCallback((data) => {
    data && setBunkerFactorSettings(data);
  }, []);

  const getCacheBunkerFactorsSetting = useCallback(async () => {
    return await getAllFromBunkerFactorsSettings();
  }, []);

  const getBunkerFactorsSetting = useCallback(
    async (manuallyFillFromStore) => {
      if (!manuallyFillFromStore && isOnline) {
        if (count > 0) return;
        request(
          "GetOrganizationBunkerFactorSettings",
          API_METHODS.GET,
          null,
          async (data) => {
            setBunkerFactorsSettings(data);
            await deleteAllFromBunkerFactorsSettings();
            await addBunkerFactorsSettingsToStore(data);
          },
          async (err) => {
            setErrMessage(`Bunker Factors settings was not loading;${err}`);
          }
        );
      } else {
        const cachedData = await getCacheBunkerFactorsSetting();
        cachedData[0] && setBunkerFactorsSettings(cachedData[0]);
      }
    },
    [isOnline, count, request, getCacheBunkerFactorsSetting]
  );

  useEffect(() => {
    getBunkerFactorsSetting();
  }, [getBunkerFactorsSetting]);

  const contextValue = useMemo(
    () => ({
      bunkerFactorSettings,
      refillBunkerFactorsSettingsFromStore: () => getBunkerFactorsSetting(true),
    }),
    [bunkerFactorSettings, getBunkerFactorsSetting]
  );

  return (
    <>
      <Alert
        severity="error"
        message={errMessage}
        onClose={() => setErrMessage(null)}
      />
      <GlobalDataContext.Provider value={contextValue}>
        {children}
      </GlobalDataContext.Provider>
    </>
  );
};

export default GlobalDataContextProvider;

export const useGlobalDataContext = () => {
  const context = useContext(GlobalDataContext);
  if (!context) {
    throw new Error(
      "useGlobalDataContext must be used within a GlobalDataContextProvider"
    );
  }
  return context;
};

import { useState } from "preact/hooks";

import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { DatePickerSelector } from "./DatePickerSelector";
import { DatePickerCalendar } from "./DatePickerCalendar";
import { BcPopover } from "../popover/BcPopover";
import { useCallback, useEffect, useMemo } from "preact/hooks";
import dayjs, { Dayjs } from "dayjs";
import {
  formatSelectedDateForDisplaying,
  generateDayjsDate,
  getTimeValue,
} from "../../utils/helpers/dateTimePickerHelpers";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Stack, TextField } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(dayOfYear);

export const utcDayJs = (...args) => dayjs(...args).utc(true);
export const getDayInYear = (...args) => dayjs(...args)?.dayOfYear();

const getHourMinuteValues = (dateTime: string) => {
  if (!dateTime) return { hrs: 0, mins: 0 };
  const hrs = +dateTime.substring(0, 2) * 1 || 0;
  const mins = +dateTime.substring(3, 5) * 1 || 0;
  return { hrs, mins };
};

type onDatePickerCLoseWithRangeArgsType = {
  startDate: string | undefined | null;
  endDate: string | undefined | null;
};
type onDatePickerClose = (
  selectedDate: string | undefined | null,
  e?: Event
) => void;
type onDatePickerCloseWithRange = (
  { startDate, endDate }: onDatePickerCLoseWithRangeArgsType,
  e?: Event
) => void;

export interface IDatePickerProps {
  selectedDate: Dayjs | string;
  selectorDateFormat?: string;
  withTimeInput: boolean;
  uneditableDatePicker?: boolean;
  placeholderText?: string;
  onDatePickerClose?: (
    selectedDate?:
      | string
      | null
      | { startDate?: string | null; endDate?: string | null },
    e?: Event
  ) => void;
  utc?: boolean;
  required?: boolean;
  showTimeOnly?: boolean;
  minDateTime?: Dayjs | string;
  maxDateTime?: Dayjs | string;
  startDate?: Dayjs | string;
  endDate?: Dayjs | string;
  withRange?: boolean;
  isOpenDatePicker?: boolean;
  setIsOpenDatePicker?: (openState: boolean) => void;
  label?: string;
  positions: "end" | "bottom" | "left" | "right" | "top"[];
}

export const BcDateTimePicker = ({
  selectedDate: selectedDateProp,
  uneditableDatePicker,
  placeholderText,
  onDatePickerClose: onDatePickerCloseProp,
  utc: utcProp,
  withTimeInput,
  required,
  showTimeOnly,
  maxDateTime: maxDateTimeProp,
  minDateTime: minDateTimeProp,
  startDate: startDateProp,
  endDate: endDateProp,
  withRange,
  isOpenDatePicker = false,
  setIsOpenDatePicker,
  label,
  errors,
  fieldName,
  positions,
}: IDatePickerProps) => {
  const utc = utcProp || !withTimeInput;

  const [shownDate, setShownDate] = useState(dayjs());
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | undefined>();
  const [minDateTime, setMinDateTime] = useState<Dayjs | undefined>();
  const [maxDateTime, setMaxDateTime] = useState<Dayjs | undefined>();
  const [timeInputValue, setTimeInputValue] = useState(
    getTimeValue(selectedDate)
  );

  const [endTimeInputValue, setEndTimeInputValue] = useState(
    getTimeValue(selectedEndDate)
  );

  const getShownDate = useCallback(() => {
    if (selectedDate) return selectedDate.clone();
    return utc ? utcDayJs() : dayjs();
  }, [utc, selectedDate]);

  const onChange = (date: Dayjs | undefined, isEnd = false) => {
    if (!date) {
      setSelectedDate(undefined);
      setSelectedEndDate(undefined);
      setMinDateTime(generateDayjsDate(minDateTimeProp, utc));
      setMaxDateTime(generateDayjsDate(maxDateTimeProp, utc));
      return;
    }
    if (withRange) {
      if (isEnd) {
        setSelectedEndDate(date);
        return;
      } else {
        setSelectedDate(date);
        return;
      }
    }
    setSelectedDate(date);
  };

  const resetDatePicker = (e) => {
    e.stopPropagation();
    const selectedDateFormatted = withRange
      ? generateDayjsDate(startDateProp, utc)
      : generateDayjsDate(selectedDateProp, utc);
    setSelectedDate(selectedDateFormatted);
    if (withRange) {
      const endDatePropFormatted = generateDayjsDate(endDateProp, utc);
      setSelectedEndDate(endDatePropFormatted);
    }
    setMinDateTime(generateDayjsDate(minDateTimeProp, utc));
    setMaxDateTime(generateDayjsDate(maxDateTimeProp, utc));
  };

  const closeDatepickerFromProp = () => {
    if (setIsOpenDatePicker) {
      setIsOpenDatePicker(false);
    }
  };

  const onDatePickerClose = (cleanDatepicker?: boolean) => {
    if (!onDatePickerCloseProp) {
      closeDatepickerFromProp();
      return;
    }
    var e = new Event("Calendar closed");
    Object.defineProperty(e, "target", { writable: false, value: {} });
    if (cleanDatepicker) {
      if (withRange) {
        onDatePickerCloseProp({ startDate: undefined, endDate: undefined }, e);
        closeDatepickerFromProp();
        return;
      }
      onDatePickerCloseProp(undefined, e);
      return;
    }
    const newSelectedDate = selectedDate ? selectedDate?.format() : null;
    const newEndDate = selectedEndDate ? selectedEndDate?.format() : null;
    if (withRange) {
      if (newSelectedDate && newEndDate) {
        onDatePickerCloseProp(
          { startDate: newSelectedDate, endDate: newEndDate },
          e
        );
      } else {
        setSelectedDate(undefined);
        setSelectedEndDate(undefined);
        onDatePickerCloseProp({ startDate: null, endDate: null }, e);
      }
      closeDatepickerFromProp();
      return;
    }
    onDatePickerCloseProp(newSelectedDate, e);
    closeDatepickerFromProp();
  };

  const cleanDatePicker = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (uneditableDatePicker) return;
    onChange(undefined);
    onDatePickerClose(true);
  };

  const handleSelectDate = (value: Dayjs) => {
    const { hrs, mins } = getHourMinuteValues(timeInputValue || "00:00");
    const newSelectedDate = value.hour(hrs).minute(mins);
    const isEndDate = withRange && selectedDate && value > selectedDate;
    onChange(newSelectedDate, isEndDate);
  };

  useEffect(() => {
    setIsPopoverOpen(isOpenDatePicker);
  }, [isOpenDatePicker]);

  useEffect(() => {
    const selectedDateFormatted = withRange
      ? generateDayjsDate(startDateProp, utc)
      : generateDayjsDate(selectedDateProp, utc);
    setSelectedDate(selectedDateFormatted);
  }, [selectedDateProp, utc, startDateProp]);

  useEffect(() => {
    if (withRange) {
      const endDatePropFormatted = generateDayjsDate(endDateProp, utc);
      setSelectedEndDate(endDatePropFormatted);
    }
  }, [endDateProp, utc]);

  useEffect(() => {
    const minDateFormatted = generateDayjsDate(minDateTimeProp, utc);
    const maxDateFormatted = generateDayjsDate(maxDateTimeProp, utc);
    setMinDateTime(minDateFormatted);
    setMaxDateTime(maxDateFormatted);
  }, [minDateTimeProp, maxDateTimeProp, utc]);

  useEffect(() => {
    if (
      !withRange &&
      !selectedDate &&
      !selectedDateProp &&
      !uneditableDatePicker
    ) {
      if (required || showTimeOnly) {
        onChange(dayjs());
      }
    }
  }, [uneditableDatePicker, required, selectedDateProp, showTimeOnly]);

  useEffect(() => {
    setShownDate(getShownDate());
    setTimeInputValue(getTimeValue(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    setEndTimeInputValue(getTimeValue(selectedEndDate));
  }, [selectedEndDate]);

  useEffect(() => {
    const { hrs, mins } = getHourMinuteValues(timeInputValue || "");

    const selectedDateHrsMinsValue = getTimeValue(selectedDate);

    if (selectedDateHrsMinsValue !== timeInputValue) {
      let newSelectedDate;
      if (selectedDate) {
        newSelectedDate = selectedDate.hour(hrs).minute(mins);
      } else {
        newSelectedDate = utc
          ? dayjs().utc(true).hour(hrs).minute(mins)
          : dayjs().hour(hrs).minute(mins);
      }
      onChange(newSelectedDate, true);
    }
  }, [timeInputValue]);

  useEffect(() => {
    const { hrs, mins } = getHourMinuteValues(endTimeInputValue || "");

    const selectedEndDateHrsMinsValue = getTimeValue(selectedEndDate);

    if (selectedEndDateHrsMinsValue !== endTimeInputValue) {
      let newSelectedEndDate;
      if (selectedEndDate) {
        newSelectedEndDate = selectedEndDate.hour(hrs).minute(mins);
      } else {
        newSelectedEndDate = utc
          ? dayjs().utc(true).hour(hrs).minute(mins)
          : dayjs().hour(hrs).minute(mins);
      }
      onChange(newSelectedEndDate, true);
    }
  }, [endTimeInputValue]);

  const toggleDatePicker = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const formattedSelectedDateTimeLabel = useMemo(
    () =>
      formatSelectedDateForDisplaying(
        withTimeInput,
        utc,
        withRange,
        selectedDate,
        selectedEndDate,
        placeholderText
      ),
    [
      withTimeInput,
      utc,
      withRange,
      selectedDate,
      selectedEndDate,
      placeholderText,
    ]
  );

  const isDatepickerClearable =
    selectedDate && !uneditableDatePicker && !required;

  const getIsDatePickerResetable = useCallback(() => {
    const newSelectedDate = selectedDate ? selectedDate?.format() : null;
    if (withRange) {
      const newEndDate = selectedEndDate ? selectedEndDate?.format() : null;

      if (selectedDateProp != newSelectedDate && endDateProp != newEndDate) {
        return true;
      }
    } else {
      if (selectedDateProp != newSelectedDate) {
        return true;
      }
    }
    return false;
  }, [selectedDate, selectedEndDate, selectedDateProp, endDateProp]);

  const isDatePickerResetable = useMemo(
    () => getIsDatePickerResetable(),
    [getIsDatePickerResetable]
  );

  const openDatePicker = useCallback(() => {
    if (!uneditableDatePicker) {
      setIsPopoverOpen(true);
    }
  }, [uneditableDatePicker]);

  return (
    <BcPopover
      isOpen={isPopoverOpen}
      setIsOpen={setIsPopoverOpen}
      onClosePopover={onDatePickerClose}
      positions={positions}
      popoverTarget={
        <TextField
          label={label || "Select Date"}
          value={showTimeOnly ? "" : formattedSelectedDateTimeLabel}
          variant="outlined"
          onClick={openDatePicker}
          disabled={uneditableDatePicker}
          error={errors && errors[fieldName]}
          helperText={errors && errors[fieldName] ? errors[fieldName] : null}
          focused
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              cursor: uneditableDatePicker ? "default" : "pointer",
            },
            "& .MuiInputBase-input": {
              cursor: uneditableDatePicker ? "default" : "pointer",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#7cd6d3",
                borderWidth: "1px",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <>
                {isDatepickerClearable && (
                  <ClearRoundedIcon
                    onClick={cleanDatePicker}
                    sx={{
                      cursor: uneditableDatePicker ? "default" : "pointer",
                      ml: 1,
                    }}
                  />
                )}
                <CalendarMonthRoundedIcon
                  onClick={openDatePicker}
                  sx={{
                    cursor: uneditableDatePicker ? "default" : "pointer",
                    ml: 1,
                  }}
                />
              </>
            ),
            readOnly: true,
          }}
        />
      }
    >
      <div className={"DatePicker"}>
        <div className="DatePicker__wrapper">
          <DatePickerSelector
            shownDate={shownDate}
            setShownDate={setShownDate}
            isDatePickerResetable={isDatePickerResetable}
            resetDatePicker={resetDatePicker}
          />

          <DatePickerCalendar
            selectedDate={selectedDate}
            endDate={selectedEndDate}
            shownDate={shownDate}
            handleSelectDate={handleSelectDate}
            minDate={minDateTime}
            maxDate={maxDateTime}
            utc={utc}
            withRange={withRange}
          />
        </div>
        {withTimeInput ? (
          <Stack
            sx={{
              borderTop: "1px solid black",
              flexDirection: "row",
              padding: "15px 10px 10px 10px",
            }}
            gap="10px"
          >
            <TextField
              type="time"
              label={withRange ? "From" : "Time"}
              value={timeInputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTimeInputValue(e.target.value)
              }
              onBlur={() => {
                if (showTimeOnly) {
                  onDatePickerClose();
                }
              }}
              sx={{ flexGrow: 1 }}
              InputLabelProps={{ shrink: true }}
            />

            {withRange ? (
              <TextField
                type="time"
                label="To"
                value={endTimeInputValue}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEndTimeInputValue(e.target.value)
                }
                sx={{ flexGrow: 1 }}
                InputLabelProps={{ shrink: true }}
              />
            ) : null}
          </Stack>
        ) : null}
      </div>
    </BcPopover>
  );
};

import { lightColors } from "../../utils/lightColors";
import { darkColors } from "../../utils/darkColors";

export const typographyPalette = (mode) => {
  if (mode === "dark") {
    return {
      h1: {
        color: darkColors.colors.tranquilTide,
      },
      h2: {
        color: darkColors.colors.tranquilTide,
      },
      h3: {
        color: darkColors.colors.tranquilTide,
      },
      body1: {
        color: darkColors.colors.brownyGray,
      },
      body2: {
        color: darkColors.colors.white,
      },
      body3: {
        color: "#C0C0C0",
      },
    };
  }
  return {
    h1: {
      color: lightColors.secondary.main,
    },
    h2: {
      color: lightColors.secondary.main,
    },
    h3: {
      color: lightColors.secondary.main,
    },
    body1: {
      color: lightColors.colors.darkGray,
    },
    body2: {
      color: lightColors.dark.main,
    },
    body3: {
      color: lightColors.colors.lightGray,
    },
  };
};
export const typographyConfig = {
  styleOverrides: {
    root: () => ({
      fontWeight: 500,
      fontFamily: "Inter",
    }),
    h1: ({ theme }) => ({
      fontSize: "20px",
      fontWeight: 500,
      color: theme.palette.components.typography.h1.color,
    }),
    h2: ({ theme }) => ({
      fontSize: "18px",
      fontWeight: 500,
      color: theme.palette.components.typography.h2.color,
    }),
    h3: ({ theme }) => ({
      fontSize: "12px",
      fontWeight: 600,
      color: theme.palette.components.typography.h3.color,
    }),
    body1: ({ theme }) => ({
      fontSize: "12px",
      fontWeight: 600,
      color: theme.palette.components.typography.body1.color,
    }),
    body2: ({ theme }) => ({
      fontSize: "16px",
      fontWeight: 400,
      color: theme.palette.components.typography.body2.color,
    }),
    body3: ({ theme }) => ({
      fontSize: "16px",
      fontWeight: 400,
      color: theme.palette.components.typography.body3.color,
    }),
  },
};

import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MultiSelect = ({ options, onChange, values, label }) => {
  const [selectedValues, setSelectedValues] = useState(values ?? []);
  const [inputValue, setInputValue] = useState("");

  const handleOptionChange = (event, newValue) => {
    setSelectedValues(newValue);
    onChange(newValue);
  };

  const handleRemoveOption = (valueToRemove) => {
    const updatedValues = selectedValues.filter(
      (value) => value.value !== valueToRemove.value
    );
    setSelectedValues(updatedValues);
    onChange(updatedValues);
  };

  const handleRemoveAllOptions = () => {
    setSelectedValues([]);
    onChange([]);
  };

  useEffect(() => {
    //     if values exists check by value in options list and return elements from it
    if (values) {
      const selectedValues = options.filter((option) =>
        values.some((value) => value === option.value)
      );
      setSelectedValues(selectedValues);
    }
  }, []);

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedValues}
      onChange={handleOptionChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label ?? "Select options"}
        />
      )}
      renderTags={(selectedValues, getTagProps) =>
        selectedValues.map((option, index) => (
          <Chip
            sx={{
              borderRadius: "2px",
              background: (theme) =>
                theme.palette.components.chip.default.background,
              height: "20px",
              ".MuiChip-label": {
                px: "10px",
                color: (theme) => theme.palette.components.chip.default.color,
              },
              ".MuiChip-deleteIcon": {
                color: (theme) => theme.palette.components.chip.default.color,
                fontSize: "12px",
              },
            }}
            deleteIcon={<CloseIcon />}
            key={option.value}
            label={option.label}
            onDelete={() => handleRemoveOption(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value}
    />
  );
};

export default MultiSelect;

import { ThemeContext } from "../../../context/ThemeContext";
import {IconButton} from "@mui/material";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
const ThemeToggle = ({sxFromProps}) => {
  return (
    <ThemeContext.Consumer>
      {([theme, dispatch]) => (
        <IconButton sx={sxFromProps ? sxFromProps : {backgroundColor: "components.themeToggle.background", color: "components.themeToggle.color"}} aria-label="mode" onClick={() => dispatch(theme)} className='circle'>
          {theme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      )}
    </ThemeContext.Consumer>
  );
};

export default ThemeToggle;

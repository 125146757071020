import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useState} from "preact/hooks";
import {callForgotPassword, getAuthStatus} from "../../../../utils/helpers/apiHelpers";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ForgotPassword = ({setErrorMessage, setScreen, setUserEmail, userEmail}) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleUserNameInput = (e) => {
        setUserEmail(e.target.value);
    };

    const goToSignIn = () => {
        setScreen('sign-in')
    }

    const onForgotPasswordFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        try {
            const data = await callForgotPassword({
                username: userEmail,
            });
            setIsLoading(false);
            if (getAuthStatus(data.type) !== "Success") {
                return setErrorMessage(getAuthStatus(data.type));
            }
            setScreen('thank-you');
        } catch (err) {
            setErrorMessage(err);
            setIsLoading(false);
        }
    };

    return  <Stack width='100%' flexDirection="column" justifyContent='center' alignItems='flex-start'>
        <Typography sx={{fontWeight: 500, fontSize: '24px', lineHeight: '29.05px', textAlign: 'left', color: 'layout.login.title', marginBottom: '30px'}} variant="h4" component="h4">
            Forgot Password
        </Typography>
        <TextField label="Your email address" variant="outlined" fullWidth  type="email" onChange={handleUserNameInput} value={userEmail} />
        <Stack justifyContent='center' alignItems='flex-start' flexDirection='column' gap='40px' mt='40px' width='100%'>
            <Button type='submit' variant='contained' fullWidth disabled={isLoading} onClick={onForgotPasswordFormSubmit} sx={{padding: '13px 20px', maxHeight: '45px'}}>Submit</Button>
            <Box sx={{backgroundColor: 'primary.main-75', width: '100%', height: '1px'}} />
            <Button variant="text" onClick={goToSignIn}><ArrowBackIcon sx={{marginRight: '12.33px'}} /> Go Back</Button>
        </Stack>
    </Stack>
}

export default ForgotPassword
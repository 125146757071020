import { useEffect, useState } from "preact/hooks";
import PortcallVoyageGroup from "../../components/portcall/PortcallVoyageGroup";
import { ASC } from "../../utils/constants/globalConstants";
import {
  sortList,
} from "../../utils/helpers/globalHelpers";

export const ScheduledContainer = ({
  scheduledPortcalls,
  setScheduledPortcalls,
  baseUrlToMainApp,
}) => {
  const [highlightedPortcallsFields, setHighlightedPortcallsFields] = useState(
    {}
  );
  const [isAnyPortcallInEditMode, setIsAnyPortcallInEditMode] = useState(false);

  useEffect(() => {
    const indexOfPortcallWithStartOfSeaPassageSetted =
      scheduledPortcalls?.findIndex(
        ({ startOfSeaPassage }) => startOfSeaPassage
      );

    if (indexOfPortcallWithStartOfSeaPassageSetted !== -1) {
      const newScheduledPortcalls = [...scheduledPortcalls];
      newScheduledPortcalls.splice(
        indexOfPortcallWithStartOfSeaPassageSetted,
        1
      );
      setScheduledPortcalls(
        sortList(newScheduledPortcalls, ASC, ["endOfSeaPassage", "eta"])
      );
      // showSuccessToast("Portcall was successfully moved to Archived portcalls");
    }

    const newHighlightedPortcallsFieldsObj = {};
    scheduledPortcalls.forEach(
      ({ portCallId }) => (newHighlightedPortcallsFieldsObj[portCallId] = [])
    );
    setHighlightedPortcallsFields(newHighlightedPortcallsFieldsObj);
  }, [scheduledPortcalls]);

  const getPreviousPortcall = (currentPortcall, listOfPortcalls) => {
    const currentPortcallIndex = listOfPortcalls?.findIndex(
      (portcall) => portcall.portCallId === currentPortcall.portCallId
    );
    return listOfPortcalls?.[currentPortcallIndex - 1];
  };

  return (
    <>
      {scheduledPortcalls?.map((voyageWithPortcalls, groupIdx) => {
        return (
            <PortcallVoyageGroup
                key={voyageWithPortcalls.portCalls[0]?.lastUpdate}
                groupIdx={groupIdx}
                groupArr={voyageWithPortcalls}
                getPreviousPortcall={getPreviousPortcall}
                portcalls={scheduledPortcalls}
                setScheduledPortcalls={setScheduledPortcalls}
                highlightedPortcallsFields={highlightedPortcallsFields}
                setHighlightedPortcallsFields={setHighlightedPortcallsFields}
                isAnyPortcallInEditMode={isAnyPortcallInEditMode}
                setIsAnyPortcallInEditMode={setIsAnyPortcallInEditMode}
                baseUrlToMainApp={baseUrlToMainApp}
            />
        )
      })}
    </>
  );
};

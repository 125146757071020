import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";

import {
  getCalendarRows,
  isFirstDateBiggerOrEqualThanSecond,
  isFirstDateBiggerThanSecond,
} from "../../utils/helpers/dateTimePickerHelpers";
import { utcDayJs } from "./BcDateTimePicker";

export interface IDatePickerCalendarProps {
  shownDate: Dayjs;
  selectedDate: Dayjs | undefined;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  handleSelectDate: (newDate: Dayjs) => void;
  utc?: boolean;
  endDate?: Dayjs;
  withRange?: boolean;
}

export const DatePickerCalendar = ({
  shownDate,
  selectedDate,
  handleSelectDate,
  minDate,
  maxDate,
  utc,
  endDate: endDateProp,
  withRange,
}: IDatePickerCalendarProps) => {
  const [endDate, setEndDate] = useState(endDateProp);
  const rows = useMemo(() => getCalendarRows(shownDate), [shownDate]);
  const today = utc ? utcDayJs() : dayjs();

  useEffect(() => {
    setEndDate(endDateProp);
  }, [endDateProp]);

  const isActiveDay = (calendarDate: Dayjs, selectedDate: Dayjs) => {
    if (!selectedDate) return false;
    const isMonthEqual = calendarDate?.month() === selectedDate.month();
    if (!isMonthEqual) return false;
    const isDayOfMonthEqual = calendarDate?.date() === selectedDate.date();
    if (isDayOfMonthEqual) {
      return true;
    } else {
      return false;
    }
  };
  const isDayInRange = (calendarDate: Dayjs, endDate: Dayjs | undefined) => {
    if (!endDate) return false;
    const startDate = selectedDate;

    const isEndDateBiggerThanCalendarDate = isFirstDateBiggerOrEqualThanSecond(
      endDate,
      calendarDate
    );
    const isCalendarDateBiggerThanStartDate =
      isFirstDateBiggerOrEqualThanSecond(calendarDate, startDate);

    if (isEndDateBiggerThanCalendarDate && isCalendarDateBiggerThanStartDate)
      return true;
    return false;
  };

  const isDayDisabled = useCallback(
    (calendarDate: Dayjs) => {
      if (!minDate && !maxDate) return false;
      const isMinDateBiggerThanCalendarDate = isFirstDateBiggerThanSecond(
        minDate,
        calendarDate
      );
      const isCalendarDateBiggerThanMaxDate = isFirstDateBiggerThanSecond(
        calendarDate,
        maxDate
      );

      if (isMinDateBiggerThanCalendarDate || isCalendarDateBiggerThanMaxDate) {
        return true;
      }

      return false;
    },
    [minDate, maxDate]
  );

  const isToday = useCallback(
    (value: Dayjs) => {
      const isSame = value.isSame(today, "day");

      return isSame;
    },
    [today]
  );

  const generateCellClassName = useCallback(
    (value: Dayjs) => {
      let className = "DatePickerCalendar__cell DatePickerCalendar__dayCell ";

      if (withRange && isDayInRange(value, endDate)) {
        className += "DatePickerCalendar__dayCell_inrange ";
      }
      if (withRange && isDayInRange(value, endDateProp)) {
        className += "DatePickerCalendar__dayCell_selected ";
      }
      if (isActiveDay(value, selectedDate)) {
        className += "DatePickerCalendar__dayCell_selected ";
      }
      if (isToday(value)) {
        className += "DatePickerCalendar__dayCell_today ";
      }
      if (isDayDisabled(value)) {
        className += "DatePickerCalendar__dayCell_disabled";
      }
      return className;
    },
    [endDate, endDateProp, selectedDate]
  );

  return (
    <>
      <div className={"DatePickerCalendar__header"}>
        {rows[0].map(({ value }, i) => (
          <div key={i} className={"DatePickerCalendar__cell"}>
            {value.format("dd").slice(0, 2)}
          </div>
        ))}
      </div>

      {rows.map((cells, rowIndex) => (
        <div key={rowIndex} className={"DatePickerCalendar__row"}>
          {cells.map(({ text, value }, i) => (
            <div
              key={`${text} - ${i}`}
              className={`${generateCellClassName(value)}`}
              onClick={() => handleSelectDate(value)}
              onMouseEnter={
                selectedDate
                  ? () => {
                      setEndDate(value);
                    }
                  : () => {}
              }
            >
              {text}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export const darkColors = {
  primary: {
    main: "#24BAB6",
    "main-75": "#7CD6D3",
    "main-50": "#B7E8E7",
    "main-25": "#EBF9F9",
    "main-15": "#D3F1F0",
  },
  secondary: {
    main: "#031954",
    "main-75": "#354776",
    "main-50": "#6E7DA4",
    "main-25": "#CDD1DD",
  },
  dark: {
    main: "#121212",
    "main-75": "#000000",
    "main-50": "#1F1F1F",
    "main-25": "#3F3F3F",
    "main-15": "#717171",
  },
  colors: {
    primaryBlue: "#42AAF5",
    successGreen: "#85EA9C",
    lightBlue: "#93D1FF",
    royalPurple: "#A773E8",
    lavender: "#D1ACFF",
    lightGrey: "#D6D6D6",
    limeGreen: "#D9FF87",
    magenta: "#DF7FEF",
    pink: "#FD8EE5",
    red: "#F14549",
    lightOrange: "#FFCF87",
    paleYellow: "#FFF48C",
    white: "#FFFFFF",
    darkGray: "#647295",
    shadowSlate: "#383838",
    tranquilTide: "#AEE1DE",
    graphiteGray: "#575757",
    blushingCoral: "#FC9A9A",
    sereneSkyline: "#E0F4F4",
    turquoiseTranquility: "#4DDAD6",
    darkgreen: "#00424E",
    hoverRed: "#FF0000",
    warning: "rgba(255, 168, 38, 1)",
    black: "#000000",
    status: {
      online: "#1BC641",
      offline: "#F14549",
      queued: "#FFA826",
    },
    brownyGray: "#8F9493",
  },
};

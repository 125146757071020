import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "preact/hooks";
import {callSetPassword, validateSetPasswordToken} from "../../../../utils/helpers/apiHelpers";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const ResetPassword = ({ setErrorMessage, setScreen }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userPassword, setUserPassword] = useState(null);
    const [passwordVerification, setPasswordVerification] = useState(null)

    const [action, setAction] = useState('');
    const [token, setToken] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const actionParam = urlParams.get('action');
        const tokenParam = urlParams.get('token');

        if (actionParam) {
            setAction(actionParam);
        }

        if (tokenParam) {
            setToken(tokenParam);
        }
    }, []);

    useEffect(() => {
        validateSetPasswordToken(token)
            .then(result => {
                if (result.status === 401) {
                    setErrorMessage("Token expired")
                    setTimeout(() => {
                        setErrorMessage(null)
                        setScreen('forgot-password');
                    }, 5000)
                }
            })
            .catch(err => console.error(err));
    }, [token]);


    const handleUserPassword = (e) => {
        setUserPassword(e.target.value);
    };

    const handleUserPasswordVerification = (e) => {
        setPasswordVerification(e.target.value);
    };

    const setNewPassword = () => {
        setErrorMessage('');
        setIsLoading(true);
        callSetPassword(token, userPassword)
            .then(result => {
                if (result.type === 6000) {
                    setScreen('reset-password-success');
                } else {
                    setErrorMessage(result.payload);
                }
            })
            .catch(err => {
                setErrorMessage('Link expired');
                console.error(err)
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <Stack width='100%' flexDirection="column" justifyContent='center' alignItems='flex-start'>
            <Typography sx={{fontWeight: 500, fontSize: '24px', lineHeight: '29.05px', textAlign: 'left', color: 'layout.login.title', marginBottom: '30px'}} variant="h4" component="h4">
                Set your password
            </Typography>
            <TextField label="Your password" variant="outlined" fullWidth type={showPassword ? 'text' : 'password'} sx={{margin: '0 0 30px 0'}} onChange={handleUserPassword} value={userPassword}                        InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    </InputAdornment>
                ),
            }} />
            <TextField label="Re-enter your password" variant="outlined" fullWidth type={showPassword2 ? 'text' : 'password'} sx={{margin: '0 0 0 0'}} onChange={handleUserPasswordVerification} value={passwordVerification}                        InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword2 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    </InputAdornment>
                ),
            }} />
            <Stack justifyContent='center' alignItems='center' flexDirection='column' gap='25px' mt='40px' width='100%'>
                <Button type='submit' variant='contained' fullWidth disabled={isLoading} onClick={setNewPassword} sx={{padding: '13px 20px', maxHeight: '45px'}}>Submit</Button>
            </Stack>
        </Stack>
    )
}

export default ResetPassword;
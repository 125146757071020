import { cloneDeep, isEqual, isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "preact/hooks";
import { BunkerFactorType } from "../../../../../utils/enums";
import { useHttp } from "../../../../../hooks/useHttp";
import { API_METHODS } from "../../../../../utils/constants/api";
import { useGlobalDataContext } from "../../../../../context/GlobalDataContextProvider";
import { updateBunkerFactorsSettings } from "../../../../../db";

export const useBDNFiguresFieldFunctionality = ({
  bunkerDetails,
  resetQuantity,
  updateBunkerFactors,
  commercialBunkerFactorPreferences,
}) => {
  const [bunkerFactorsModalProps, setBunkerFactorsModalProps] = useState({
    isOpen: false,
  });

  const { bunkerFactorSettings } = useGlobalDataContext();

  const currentBunkerFactorsSettings = useMemo(
    () =>
      bunkerFactorSettings?.find(
        (singleBunkerFactorSetting) =>
          singleBunkerFactorSetting.productType === bunkerDetails.type
      ),
    [bunkerFactorSettings, bunkerDetails.type]
  );

  const {
    isFillingRequired,
    bunkerProductTypesWithImmediateFactorsRequirement,
  } = commercialBunkerFactorPreferences ?? {};

  const isBunkerProductTypeWithImmediateFactorsRequirement = useMemo(
    () =>
      bunkerProductTypesWithImmediateFactorsRequirement?.includes(
        bunkerDetails.type
      ),
    [bunkerProductTypesWithImmediateFactorsRequirement, bunkerDetails.type]
  );

  const onSaveHandler = useCallback(
    (newBunkerFactors) => {
      updateBunkerFactors(
        newBunkerFactors.map((singleUpdatedBunkerFactor) => ({
          bunkerFactorType: singleUpdatedBunkerFactor.type,
          value: singleUpdatedBunkerFactor.value || null,
        }))
      );
    },
    [updateBunkerFactors]
  );

  const openBunkerFactorsModal = useCallback(
    (callAlsoWhenCanceling) => {
      setBunkerFactorsModalProps({
        callAlsoWhenCanceling:
          isBunkerProductTypeWithImmediateFactorsRequirement
            ? callAlsoWhenCanceling
            : () =>
                onSaveHandler(
                  getInitialEmptyFactors(undefined, bunkerDetails.bunkerFactors)
                ),
        isOpen: true,
        initialValues: {
          quantity: bunkerDetails.quantity,
          bunkerFactors: getInitialEmptyFactors(
            currentBunkerFactorsSettings?.factorValues,
            bunkerDetails.bunkerFactors
          ),
          id: currentBunkerFactorsSettings?.id,
        },
      });
    },
    [
      currentBunkerFactorsSettings,
      bunkerDetails.quantity,
      bunkerDetails.bunkerFactors,
      onSaveHandler,
      isBunkerProductTypeWithImmediateFactorsRequirement,
    ]
  );

  const onBlurHandler = useCallback(() => {
    if (isFillingRequired) {
      if (bunkerDetails?.quantity && !bunkerDetails?.bunkerFactors?.length) {
        openBunkerFactorsModal(resetQuantity);
      }
    }
  }, [isFillingRequired, bunkerDetails, resetQuantity, openBunkerFactorsModal]);

  const onClickOnIcon = useCallback(() => {
    if (bunkerDetails?.bunkerFactors?.length) {
      if (checkIfEmissionsNeedsToBeQueried(bunkerDetails.bunkerFactors)) {
        openBunkerFactorsModal();
      } else {
        setBunkerFactorsModalProps({
          isOpen: true,
          initialValues: {
            quantity: bunkerDetails.quantity,
            bunkerFactors: getInitialEmptyFactors(
              undefined,
              bunkerDetails.bunkerFactors
            ),
          },
        });
      }
    } else {
      openBunkerFactorsModal();
    }
  }, [bunkerDetails, openBunkerFactorsModal]);

  return {
    bunkerFactorsModalProps,
    onBlurHandler,
    onClickOnIcon,
    onSaveHandler,
    showIcon: Boolean(bunkerDetails?.bunkerFactors?.length),
    setIsOpen: (flag) => setBunkerFactorsModalProps({ isOpen: flag }),
    isBunkerProductTypeWithImmediateFactorsRequirement,
  };
};

export const useBunkerFactorsModalFunctionality = ({
  setModalIsOpen,
  onSave,
  initialValues,
  isBunkerProductTypeWithImmediateFactorsRequirement,
}) => {
  const { refillBunkerFactorsSettingsFromStore } = useGlobalDataContext();

  const { id: bunkerId } = initialValues;
  const [values, setValues] = useState({
    quantity: initialValues?.quantity,
    bunkerFactors: initialValues?.bunkerFactors,
  });
  const { request } = useHttp();

  const [errors, setErrors] = useState();

  const setBunkerFactorValue = useCallback((bunkerFactorType, value) => {
    setValues((prev) => ({
      ...prev,
      bunkerFactors: prev.bunkerFactors.map((singleBunkerFactor) =>
        singleBunkerFactor.type === bunkerFactorType
          ? { ...singleBunkerFactor, value }
          : singleBunkerFactor
      ),
    }));
  }, []);

  const validateBunkerFactors = useCallback(
    (valuesToCheck) => {
      const initialErrors = {
        bunkerFactors: valuesToCheck?.bunkerFactors?.map(() => {
          return {};
        }),
      };

      const errors = cloneDeep(initialErrors);

      if (valuesToCheck.bunkerFactors) {
        valuesToCheck.bunkerFactors.forEach((item, i) => {
          if (
            isBunkerProductTypeWithImmediateFactorsRequirement &&
            !item?.value
          ) {
            errors.bunkerFactors[i].value = "Required";
          }
        });
      }

      if (!isEqual(errors, initialErrors)) {
        return errors;
      }

      return {};
    },
    [isBunkerProductTypeWithImmediateFactorsRequirement]
  );

  const updateBunkerFactorsCallback = useCallback(
    async (submitBunkerFactors, id) => {
      const preparedBunkerFactors = submitBunkerFactors
        .filter((singleBunkerFactor) =>
          [
            BunkerFactorType.EmissionConversionEuMrv,
            BunkerFactorType.EmissionConversionImoDcs,
          ].includes(singleBunkerFactor.type)
        )
        .map((singleBunkerFactor) => ({
          ...singleBunkerFactor,
          value:
            singleBunkerFactor.value != null
              ? Number(singleBunkerFactor.value)
              : null,
        }));

      await updateBunkerFactorsSettings(
        preparedBunkerFactors,
        id,
        refillBunkerFactorsSettingsFromStore
      );
    },
    [refillBunkerFactorsSettingsFromStore]
  );

  const modalSubmitHandler = useCallback(() => {
    setErrors();
    const errors = validateBunkerFactors(values);
    if (!isEmpty(errors)) {
      setErrors(errors);
    } else {
      const workflowEmissionsWasUpdated = Boolean(
        bunkerId &&
          values.bunkerFactors.some(
            (singleBunkerFactor, singleBunkerFactorIndex) =>
              [
                BunkerFactorType.EmissionConversionEuMrv,
                BunkerFactorType.EmissionConversionImoDcs,
              ].includes(singleBunkerFactor.type) &&
              singleBunkerFactor.value !=
                initialValues.bunkerFactors[singleBunkerFactorIndex].value
          )
      );

      if (workflowEmissionsWasUpdated) {
        const updateBunkerFactors = () => {
          if (!navigator.onLine) {
            onSave(values.bunkerFactors);
            setModalIsOpen(false);
          }
          updateBunkerFactorsCallback(values.bunkerFactors, bunkerId);
        };
        request(
          "UpdatePortCallOrganizationBunkerFactorSetting",
          API_METHODS.PUT,
          {
            id: bunkerId,
            factorValues: values.bunkerFactors.filter((singleBunkerFactor) =>
              [
                BunkerFactorType.EmissionConversionEuMrv,
                BunkerFactorType.EmissionConversionImoDcs,
              ].includes(singleBunkerFactor.type)
            ),
          },
          () => {
            onSave(values.bunkerFactors);
            setModalIsOpen(false);
            updateBunkerFactors();
          },
          (err) => {
            setErrors({ generalError: err });
          },
          updateBunkerFactors
        );
      } else {
        onSave(values.bunkerFactors);
        setModalIsOpen(false);
      }
    }
  }, [
    values,
    validateBunkerFactors,
    onSave,
    request,
    bunkerId,
    setModalIsOpen,
    initialValues.bunkerFactors,
    updateBunkerFactorsCallback,
  ]);

  return { values, errors, modalSubmitHandler, setBunkerFactorValue };
};

export const checkIfEmissionsNeedsToBeQueried = (bunkerFactors) => {
  return [
    BunkerFactorType.EmissionConversionEuMrv,
    BunkerFactorType.EmissionConversionImoDcs,
  ].some(
    (singleRequiredEmission) =>
      bunkerFactors.find(
        (singleBunkerFactor) =>
          singleBunkerFactor.bunkerFactorType === singleRequiredEmission
      )?.value == null
  );
};

export const getInitialEmptyFactors = (bunkerFactors, prospectBunkers) => {
  return Object.keys(BunkerFactorType)
    .filter((key) => !isNaN(Number(BunkerFactorType[key])))
    .map((key) => ({
      type: BunkerFactorType[key],
      value:
        prospectBunkers?.find(
          (singleBunkerFactor) =>
            singleBunkerFactor.bunkerFactorType === BunkerFactorType[key]
        )?.value ??
        bunkerFactors?.find(
          (singleBunkerFactor) =>
            singleBunkerFactor.type === BunkerFactorType[key]
        )?.value,
    }));
};
export const getBunkerFactorLabels = (factorType) => {
  switch (factorType) {
    case BunkerFactorType.EmissionConversionEuMrv:
      return {
        label: "EU MRV",
        metric: "gCO2/gFuel",
      };
    case BunkerFactorType.EmissionConversionImoDcs:
      return {
        label: "IMO DCS",
        metric: "gCO2/gFuel",
      };
    case BunkerFactorType.FuelCarbonIntesityEuReg:
      return { label: "LCBV", metric: "kg/MJ" };
    case BunkerFactorType.LowerCalorific:
      return {
        label: "Carbon intensity",
        metric: "gCO2e/MJ",
      };
    default:
      return { label: "", metric: "" };
  }
};

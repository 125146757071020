import { BcInput } from "../../../globalComponents/input/BcInput";
import { BunkerFactorType, BunkerProductType } from "../../../../utils/enums";
import BunkersLiftedBDNFiguresField from "../BunkersLifted/BunkersLiftedBDNFiguresField/BunkersLiftedBDNFiguresField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CustomTabs from "../../../../mui/components/tabs/Tabs";
import {
  getBunkerFactorLabels,
  getInitialEmptyFactors,
} from "../BunkersLifted/BunkersLiftedBDNFiguresField/useBDNFiguresFieldFunctionality";

const displayRowOnNotEditMode = (liftedBunkerProduct) =>
  Boolean(liftedBunkerProduct);

const BunkersLifted = ({
  editMode,
  liftedBunkerProductsInPort,
  setFieldValue,
  setLiftedBunkerProductField,
  setLiftedBunkerProductBargeField,
  principalOrganizationId,
  commercialBunkerFactorPreferences,
  bunkersTaken,
  setBunkerFactorsValue,
  bunkerFactorSettings,
  bunkerFactorsValue,
  initialBunkerFactorsValue,
  errors,
  selectedTab,
  handleTabChange,
  tabLabels,
}) => {
  const {
    isFillingRequired,
    bunkerProductTypesWithImmediateFactorsRequirement,
  } = commercialBunkerFactorPreferences ?? {};

  const filteredBunkerProducts =
    !isFillingRequired && !editMode
      ? liftedBunkerProductsInPort
      : liftedBunkerProductsInPort.filter((_, index) => index === selectedTab);

  return (
    <Box px="18px">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "49px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: "8px",
          }}
        >
          <Typography variant="h2">Bunkers lifted?</Typography>
          <Box ml="60px" pb="3px">
            <Checkbox
              checked={bunkersTaken}
              sx={{
                color: editMode ? "#26A69A" : "#B0BEC5",
                "&.Mui-disabled": {
                  color: "#CFD8DC",
                },
                "&.Mui-checked": {
                  color: editMode ? "#26A69A" : "#B0BEC5",
                  "&.Mui-disabled": {
                    color: "#CFD8DC",
                  },
                },
                ...(isFillingRequired
                  ? { padding: 0 }
                  : { pt: "15px", pb: "10px" }),
              }}
              onChange={(e) => setFieldValue("bunkersTaken", e.target.checked)}
              disabled={!editMode}
            />
          </Box>
        </Box>
        {(isFillingRequired || editMode) && bunkersTaken && (
          <CustomTabs
            tabLabels={tabLabels}
            handleTabChange={handleTabChange}
            value={selectedTab}
            liftedBunkerProductsInPort={liftedBunkerProductsInPort}
          />
        )}
      </Box>
      <Divider
        sx={{
          backgroundColor: "components.divider.color",
        }}
      />
      {bunkersTaken && (
        <>
          {editMode ||
          liftedBunkerProductsInPort?.some(displayRowOnNotEditMode) ? (
            <Box>
              {filteredBunkerProducts.map((singleBunkerProduct, index) => {
                const currentBunkerFactorsSettings = bunkerFactorSettings?.find(
                  (singleBunkerFactorSetting) =>
                    singleBunkerFactorSetting.productType ===
                    singleBunkerProduct.type
                );
                const bunkerFactorsSettingsInLocalStorage =
                  localStorage.getItem("bunkerFactorsSettings") &&
                  localStorage.getItem("bunkerFactorsSettings") !==
                    "undefined" &&
                  (JSON.parse(localStorage.getItem("bunkerFactorsSettings")) ??
                    []);
                const initialFactorValues = navigator.onLine
                  ? getInitialEmptyFactors(
                      currentBunkerFactorsSettings?.factorValues,
                      singleBunkerProduct.bunkerFactors
                    )
                  : initialBunkerFactorsValue
                      .find(
                        (factor) =>
                          factor.productType === singleBunkerProduct.type
                      )
                      .bunkerFactors.map((item) => {
                        const currentBunkerFactorInBunkerFactorsSettings =
                          currentBunkerFactorsSettings?.factorValues.find(
                            (singleBunkerFactorSetting) =>
                              singleBunkerFactorSetting.productType ===
                              item.type
                          );

                        return {
                          type: item.type,
                          value:
                            currentBunkerFactorInBunkerFactorsSettings?.value ??
                            item.value,
                        };
                      });

                const labelAndMetricForFactorsWithTheirValue =
                  initialFactorValues.map((item) => {
                    const { label, metric: additionalText } =
                      getBunkerFactorLabels(item.type);
                    return {
                      label,
                      additionalText,
                      value: item.value,
                      type: item.type,
                    };
                  });

                if (editMode) {
                  return (
                    <Box
                      key={singleBunkerProduct.type}
                      mb="20px"
                      display="grid"
                      sx={{
                        gridTemplateColumns: "repeat(4, 1fr)",
                      }}
                    >
                      <Box width="236px" mt="25px">
                        <BunkersLiftedBDNFiguresField
                          principalOrganizationId={principalOrganizationId}
                          bunkerDetails={singleBunkerProduct}
                          commercialBunkerFactorPreferences={
                            commercialBunkerFactorPreferences
                          }
                          setLiftedBunkerProductField={(fieldName, value) =>
                            setLiftedBunkerProductField(
                              singleBunkerProduct.type,
                              fieldName,
                              value
                            )
                          }
                        />
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        mt="25px"
                        width="236px"
                      >
                        <BcInput
                          type="text"
                          label="Vessel figures"
                          value={singleBunkerProduct.vesselQuantity}
                          appendLabel={"mt"}
                          onInput={(value) => {
                            const formattedTargetValue = value
                              .replace(/[^0-9.,]/g, "")
                              .replace(/,/g, ".");
                            setLiftedBunkerProductField(
                              singleBunkerProduct.type,
                              "vesselQuantity",
                              formattedTargetValue
                            );
                          }}
                          useAutoStep
                          autocomplete="off"
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="25px"
                        width="236px"
                      >
                        <BcInput
                          type="text"
                          label="Barge figures"
                          value={singleBunkerProduct.barge?.quantity}
                          appendLabel={"mt"}
                          onInput={(value) => {
                            const formattedTargetValue = value
                              .replace(/[^0-9.,]/g, "")
                              .replace(/,/g, ".");
                            setLiftedBunkerProductBargeField(
                              singleBunkerProduct.type,
                              "quantity",
                              formattedTargetValue
                            );
                          }}
                          useAutoStep
                          autocomplete="off"
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="25px"
                        width="236px"
                      >
                        <BcInput
                          type="text"
                          label="Barge name"
                          value={singleBunkerProduct.barge?.name}
                          onInput={(value) => {
                            setLiftedBunkerProductBargeField(
                              singleBunkerProduct.type,
                              "name",
                              value
                            );
                          }}
                          useAutoStep
                          autocomplete="off"
                          sx={{
                            width: "236px",
                          }}
                        />
                      </Box>

                      {isFillingRequired &&
                        labelAndMetricForFactorsWithTheirValue.map((item) => {
                          const inputValue =
                            bunkerFactorsValue
                              .find(
                                (factor) =>
                                  factor.productType ===
                                  singleBunkerProduct.type
                              )
                              .bunkerFactors.find(
                                (singleBunkerFactor) =>
                                  singleBunkerFactor.type === item.type
                              ).value ??
                            initialBunkerFactorsValue
                              .find(
                                (factor) =>
                                  factor.productType ===
                                  singleBunkerProduct.type
                              )
                              .bunkerFactors.find(
                                (singleBunkerFactor) =>
                                  singleBunkerFactor.type === item.type
                              ).value ??
                            (
                              bunkerFactorsSettingsInLocalStorage?.length &&
                              bunkerFactorsSettingsInLocalStorage.find(
                                (singleBunkerFactorSetting) =>
                                  singleBunkerFactorSetting.productType ===
                                  singleBunkerProduct.type
                              )
                            )?.factorValues
                              .filter((singleBunkerFactorSetting) =>
                                [
                                  BunkerFactorType.EmissionConversionEuMrv,
                                  BunkerFactorType.EmissionConversionImoDcs,
                                ].includes(
                                  singleBunkerFactorSetting?.bunkerFactorType
                                )
                              )
                              .find(
                                (singleBunkerFactorSetting) =>
                                  singleBunkerFactorSetting?.bunkerFactorType ===
                                  item?.type
                              )?.value;

                          return (
                            <Box
                              display="flex"
                              alignItems="center"
                              key={item.label}
                              width="236px"
                              mt="20px"
                            >
                              <BcInput
                                type="text"
                                label={item.label}
                                value={inputValue}
                                appendLabel={item.additionalText}
                                errorText={
                                  errors[
                                    `${
                                      BunkerProductType[
                                        singleBunkerProduct.type
                                      ]
                                    }-${item.label}`
                                  ]
                                }
                                onInput={(value) => {
                                  const formattedTargetValue = value
                                    .replace(/[^0-9.,]/g, "")
                                    .replace(/,/g, ".");
                                  setBunkerFactorsValue(
                                    bunkerFactorsValue.map((factor) => {
                                      if (
                                        factor.productType ===
                                        singleBunkerProduct.type
                                      ) {
                                        return {
                                          ...factor,
                                          bunkerFactors:
                                            factor.bunkerFactors.map(
                                              (singleBunkerFactor) =>
                                                singleBunkerFactor.type ===
                                                item.type
                                                  ? {
                                                      ...singleBunkerFactor,
                                                      value:
                                                        formattedTargetValue,
                                                    }
                                                  : singleBunkerFactor
                                            ),
                                        };
                                      }
                                      return factor;
                                    })
                                  );
                                }}
                                disabled={Boolean(
                                  [
                                    BunkerFactorType.EmissionConversionEuMrv,
                                    BunkerFactorType.EmissionConversionImoDcs,
                                  ].includes(item.type) &&
                                    (initialBunkerFactorsValue
                                      .find(
                                        (factor) =>
                                          factor.productType ===
                                          singleBunkerProduct.type
                                      )
                                      .bunkerFactors.find(
                                        (singleBunkerFactor) =>
                                          singleBunkerFactor.type === item.type
                                      ).value != null ||
                                      (bunkerFactorsSettingsInLocalStorage?.length &&
                                        bunkerFactorsSettingsInLocalStorage
                                          .find(
                                            (singleBunkerFactorSetting) =>
                                              singleBunkerFactorSetting.productType ===
                                              singleBunkerProduct.type
                                          )
                                          ?.factorValues.find(
                                            (singleBunkerFactorSetting) =>
                                              singleBunkerFactorSetting?.bunkerFactorType ===
                                              item?.type
                                          )?.value))
                                )}
                                useAutoStep
                                autocomplete="off"
                                required={bunkerProductTypesWithImmediateFactorsRequirement?.includes(
                                  item.type
                                )}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  );
                }
                if (displayRowOnNotEditMode(singleBunkerProduct)) {
                  return (
                    <Box
                      key={singleBunkerProduct.type}
                      mt={!index ? "20px" : 0}
                      mb="20px"
                    >
                      <Typography variant="h3">
                        {BunkerProductType[singleBunkerProduct.type]}
                      </Typography>
                      <Box
                        display="grid"
                        sx={{
                          gridTemplateColumns: "repeat(4, 1fr)",
                        }}
                      >
                        <Stack mt="10px">
                          <Typography variant="body1">BDN figures: </Typography>
                          <Typography
                            variant="body2"
                            mt="9px"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {singleBunkerProduct.quantity
                              ? `${singleBunkerProduct.quantity}`
                              : "-"}
                            {singleBunkerProduct.quantity && (
                              <Typography
                                component="span"
                                variant="body3"
                                mr="52px"
                              >
                                mt
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                        <Stack mt="10px">
                          <Typography variant="body1">
                            Vessel figures:{" "}
                          </Typography>
                          <Typography
                            variant="body2"
                            mt="9px"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {singleBunkerProduct.vesselQuantity
                              ? `${singleBunkerProduct.vesselQuantity}`
                              : "-"}
                            {singleBunkerProduct.vesselQuantity && (
                              <Typography
                                component="span"
                                variant="body3"
                                mr="52px"
                              >
                                mt
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                        <Stack mt="10px">
                          <Typography variant="body1">
                            Barge figures:
                          </Typography>
                          <Typography
                            variant="body2"
                            mt="9px"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {singleBunkerProduct.barge?.quantity
                              ? `${singleBunkerProduct.barge.quantity}`
                              : "-"}
                            {singleBunkerProduct.barge?.quantity && (
                              <Typography
                                component="span"
                                variant="body3"
                                mr="52px"
                              >
                                mt
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                        <Stack mt="10px">
                          <Typography variant="body1">Barge name: </Typography>
                          <Typography variant="body2" mt="9px">
                            {singleBunkerProduct.barge?.name || "-"}
                          </Typography>
                        </Stack>
                        {isFillingRequired &&
                          labelAndMetricForFactorsWithTheirValue.map((item) => {
                            return (
                              <Stack key={item.label} mt="10px">
                                <Typography variant="body1">
                                  {item.label}
                                </Typography>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                >
                                  <Typography variant="body2" mt="9px">
                                    {bunkerFactorsValue
                                      .find(
                                        (factor) =>
                                          factor.productType ===
                                          singleBunkerProduct.type
                                      )
                                      .bunkerFactors.find(
                                        (singleBunkerFactor) =>
                                          singleBunkerFactor.type === item.type
                                      ).value
                                      ? `${
                                          bunkerFactorsValue
                                            .find(
                                              (factor) =>
                                                factor.productType ===
                                                singleBunkerProduct.type
                                            )
                                            .bunkerFactors.find(
                                              (singleBunkerFactor) =>
                                                singleBunkerFactor.type ===
                                                item.type
                                            ).value
                                        }`
                                      : "-"}
                                  </Typography>
                                  {bunkerFactorsValue
                                    .find(
                                      (factor) =>
                                        factor.productType ===
                                        singleBunkerProduct.type
                                    )
                                    .bunkerFactors.find(
                                      (singleBunkerFactor) =>
                                        singleBunkerFactor.type === item.type
                                    ).value && (
                                    <Typography
                                      component="span"
                                      variant="body3"
                                      mt="7px"
                                      mr="52px"
                                    >
                                      {item.additionalText}
                                    </Typography>
                                  )}
                                </Box>
                              </Stack>
                            );
                          })}
                      </Box>
                    </Box>
                  );
                }
              })}
            </Box>
          ) : (
            <Typography variant="body1">No bunkers lifted yet</Typography>
          )}
          <Divider
            sx={{
              backgroundColor: "components.divider.color",
            }}
          />
        </>
      )}
    </Box>
  );
};

export default BunkersLifted;

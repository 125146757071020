import {lightColors} from "../../utils/lightColors";
import {darkColors} from "../../utils/darkColors";

export const alertPalette = (mode) => {
    if(mode === 'dark') {
        return  {
            success: {
                background: "#282828",
                color: darkColors.colors.limeGreen,
            },
            error: {
                background: "#282828",
                color: darkColors.colors.blushingCoral,
            },
            warning: {
                background: "#282828",
                color: darkColors.colors.lightOrange,
            }
        }
    }
    return {
        success: {
            background: lightColors.colors.alerts.green,
            color: lightColors.colors.white,
        },
        error: {
            background: lightColors.colors.alerts.red,
            color: lightColors.colors.white,
        },
        warning: {
            background: lightColors.colors.alerts.yellow,
            color: lightColors.secondary.main,
        }
    }
}

export const alertConfig = {
    styleOverrides: {
        root:  ({ theme }) => ({
            borderRadius: `6px`,
            boxShadow: "none",
            padding: '6px 16px',
            width: 'fit-content',
            "&:hover": {
                boxShadow: "none",
            },
        }),
        filledSuccess: ({ theme }) => ({
            background: theme.palette.components.alert.success.background,
            color: theme.palette.components.alert.success.color
        }),
        filledError: ({ theme }) => ({
            background: theme.palette.components.alert.error.background,
            color: theme.palette.components.alert.error.color
        }),
        filledWarning: ({ theme }) => ({
            background: theme.palette.components.alert.warning.background,
            color: theme.palette.components.alert.warning.color
        })
    },
};

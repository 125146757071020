import { buttonPalette } from "../components/button/config";
import { lightColors } from "./lightColors";
import { iconButtonPalette } from "../components/icon-button/config";
import { alertPalette } from "../components/alert/config";
import { textfieldPalette } from "../components/textfield/config";
import { darkColors } from "./darkColors";
import { loginPalette } from "../../routes/login/config";
import { themeTogglePalette } from "../../components/globalComponents/ThemeToggle/config";
import { navbarPalette } from "../../components/navbar/config";
import { cardPalette } from "../../components/globalComponents/card/config";
import { portcallsPalette } from "../../components/portcalls/config";
import { loaderPalette } from "../../components/loader/config";
import { dividerPalette } from "../components/divider/config";
import { typographyPalette } from "../components/typography/config";
import { selectPalette } from "../components/select/config";
import { iconsPalette } from "../components/icons/config";
import { chipPalette } from "../components/chip/config";
import { checkboxPalette } from "../components/checkbox/config";
import { tabPalette } from "../components/tabs/config";
import {
  autocompleteConfig,
  autocompletePalette,
} from "../components/autocomplete/config";

const basePalette = (mode) => ({
  subColors: {
    gray: "#515151",
    orange: "#FFCF87",
    yellow: "#FFF48C",
    yellowgreen: "#D9FF87",
    green: "#85EA9C",
    blue: "#93D1FF",
    darkblue: "#42AAF5",
    violet: "#D1ACFF",
    violetDark: "#A773E8",
    purple: "#FD8EE5",
    purpleDark: "#DF7FEF",
    grey: "#D6D6D6",
  },
  layout: {
    login: {
      ...loginPalette(mode),
    },
    navbar: {
      ...navbarPalette(mode),
    },
    portcalls: {
      ...portcallsPalette(mode),
    },
  },
  components: {
    button: {
      ...buttonPalette(mode),
    },
    iconButton: {
      ...iconButtonPalette(mode),
    },
    alert: {
      ...alertPalette(mode),
    },
    textfield: {
      ...textfieldPalette(mode),
    },
    themeToggle: {
      ...themeTogglePalette(mode),
    },
    card: {
      ...cardPalette(mode),
    },
    loader: {
      ...loaderPalette(mode),
    },
    divider: {
      ...dividerPalette(mode),
    },
    typography: {
      ...typographyPalette(mode),
    },
    select: {
      ...selectPalette(mode),
    },
    icons: {
      ...iconsPalette(mode),
    },
    chip: {
      ...chipPalette(mode),
    },
    tab: {
      ...tabPalette(mode),
    },
    autocomplete: {
      ...autocompletePalette(mode),
    },
  },
});
const useGeneratePalette = (mode) => {
  const colors = mode === "light" ? lightColors : darkColors;

  const base = basePalette(mode);

  const palette = {
    ...base,
    primary: {
      main: colors.primary.main,
      "main-75": colors.primary["main-75"],
      "main-50": colors.primary["main-50"],
      "main-25": colors.primary["main-25"],
      "main-15": colors.primary["main-15"],
      "main-5": colors.primary["main-5"],
    },
    secondary: {
      main: colors.secondary.main,
      "main-75": colors.secondary["main-75"],
      "main-50": colors.secondary["main-50"],
      "main-25": colors.secondary["main-25"],
    },
    dark: {
      main: colors.dark.main,
      "main-75": colors.dark["main-75"],
      "main-50": colors.dark["main-50"],
      "main-25": colors.dark["main-25"],
    },
    subColors: {
      ...basePalette.subColors,
      white: colors.colors.white,
      red: colors.colors.red,
      darkGray: colors.colors.darkGray,
    },
  };

  return palette;
};

export default useGeneratePalette;

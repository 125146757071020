import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/material";

const CustomTabs = ({
  tabLabels,
  handleTabChange,
  value,
  liftedBunkerProductsInPort,
}) => {
  return (
    <Tabs
      value={value}
      onChange={handleTabChange}
      sx={{
        mt: "1px",
        ".MuiTabs-indicator": {
          height: "3px",
          backgroundColor: "rgba(36, 186, 182, 1)",
          width: "auto",
          marginLeft: "0px",
        },
        ".MuiTab-root": {
          textTransform: "none",
          fontWeight: 600,
          fontSize: "14px",
          color: "components.tab.color",
          px: 0,
          pt: "20px",
          "&.Mui-selected": {
            color: "rgba(36, 186, 182, 1)",
          },
          minWidth: "auto",
          alignItems: "center",
        },
        ".MuiTabs-flexContainer": {
          justifyContent: "space-around",
          gap: "44px",
        },
      }}
    >
      {tabLabels.map((label, index) => {
        const bunkerProduct = liftedBunkerProductsInPort.find(
          (bunkerProduct) => {
            return bunkerProduct.type === label.type;
          }
        );
        const hasCheckIcon =
          bunkerProduct?.bunkerFactors?.length &&
          bunkerProduct?.bunkerFactors?.every(
            (singleBunkerFactor) =>
              singleBunkerFactor?.bunkerFactorType !== 0 &&
              singleBunkerFactor.value
          );
        return (
          <Tab
            key={index}
            label={
              <Box display="flex" alignItems="center">
                <Box>{label.label}</Box>
                {hasCheckIcon ? (
                  <CheckCircleIcon
                    sx={{
                      fontSize: "12px",
                      color: "#1BC641",
                      ml: "4px",
                    }}
                  />
                ) : null}
              </Box>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;

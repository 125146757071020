import { BcInput } from "../../../globalComponents/input/BcInput";
import { fuelTypesEnums } from "../../utils/constants";
import { Box, Stack, Typography } from "@mui/material";
import FreshWater from "./FreshWater";
import Tugs from "./Tugs";

const Bunkers = ({
  isArrival,
  editMode,
  bunkers,
  setBunkersFuel,
  isLoading,
  freshWater,
  setFreshWater,
  tugs,
  setTugs,
  errorsStateForTugs,
}) => {
  const generateFuelTypeLabel = (fuelType) => {
    return fuelTypesEnums?.find(({ value }) => value === fuelType)?.label;
  };

  return (
    <Stack alignItems="start" width="100%" mt={isArrival ? "8px" : "16px"}>
      <Typography variant="h3" mb={editMode ? 0 : "7px"}>
        Bunkers
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          width: "100%",
          gap: !editMode ? "20px 0px" : "0px",
        }}
      >
        {bunkers.map((item, index) => {
          return (
            <Box
              key={`${item.id}_${item.fuelType}_${
                isArrival ? "Arrival" : "Departure"
              }`}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!editMode ? (
                <Typography variant="body1">
                  {generateFuelTypeLabel(item.type)}
                </Typography>
              ) : null}
              {editMode ? (
                <Box width="236px" mt="15px">
                  <BcInput
                    sx={{
                      textAlign: "end",
                    }}
                    type="text"
                    label={generateFuelTypeLabel(item.type)}
                    value={item.quantity}
                    onInput={(value) => {
                      const formattedTargetValue = value
                        .replace(/[^0-9.,]/g, "")
                        .replace(/,/g, ".");
                      setBunkersFuel(index, formattedTargetValue, isArrival);
                    }}
                    sx={{
                      width: "236px",
                    }}
                    isLoading={isLoading}
                  />
                </Box>
              ) : (
                <Typography variant="body2" mt="9px">
                  {item.quantity ?? "-"}
                </Typography>
              )}
            </Box>
          );
        })}
        <FreshWater
          isArrival={isArrival}
          editMode={editMode}
          isLoading={isLoading}
          freshWater={freshWater}
          setFreshWater={setFreshWater}
        />
        <Tugs
          isArrival={isArrival}
          editMode={editMode}
          tugs={tugs}
          setTugs={setTugs}
          errorsStateForTugs={errorsStateForTugs}
        />
      </Box>
    </Stack>
  );
};

export default Bunkers;

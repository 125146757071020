import { ASC, DESC } from "../constants/globalConstants";

export const areEqual = (obj1, obj2) => {
  return (
    JSON.stringify(obj1, (key, value) => (value === "" ? undefined : value)) ===
    JSON.stringify(obj2, (key, value) => (value === "" ? undefined : value))
  );
};

export const checkIfListOfObjects = (list) => {
  return list
    .map((item) => typeof item)
    .every((typeOfItem) => typeOfItem === "object");
};

const getAppropriateFieldName = (fieldNameArrSortedOnPriority, object) => {
  let appropriateFieldName;
  if (typeof fieldNameArrSortedOnPriority === "string") {
    appropriateFieldName = fieldNameArrSortedOnPriority;
  }
  for (let i = 0; i < fieldNameArrSortedOnPriority.length; i++) {
    if (object[fieldNameArrSortedOnPriority[i]]) {
      appropriateFieldName = fieldNameArrSortedOnPriority[i];
      break;
    }
  }
  return appropriateFieldName;
};

const sortListNullUndefinedToTheEnd = (a, b, sortDirection) => {
  if (a === b) return 0;
  if (a == null) return 1;
  if (b == null) return -1;
  sortListClassic(a, b, sortDirection);
};

const sortListClassic = (a, b, sortDirection) => {
  if (sortDirection === "ASC") {
    if (a > b) return 1;
    if (a < b) return -1;
  }
  if (sortDirection === "DESC") {
    if (a > b) return -1;
    if (a < b) return 1;
  }
};

export const sortList = (
  list,
  sortDirection = "ASC",
  fieldNamesArrSortedOnFieldNamePriorityOrFieldNameString
) => {
  // fieldNamesArrSortedOnFieldNamePriorityOrFieldNameString is array of
  // fieldNames strings from the most prioritized to less, eg:
  // ['first'] or ['name','lastName','age'] (at first we ll check name, if not name then lastName etc)
  // Also could be undefined or [] - in this case arr would be just simply sorted
  // or string "name" (would be sorted based on one fieldName)
  if (!list.length) return [];
  const isListOfObjects = checkIfListOfObjects(list);
  const newList = [...list];
  let sortedList;
  if (
    isListOfObjects &&
    fieldNamesArrSortedOnFieldNamePriorityOrFieldNameString?.length
  ) {
    sortedList = newList?.sort((a, b) => {
      const sortByFieldNameA = getAppropriateFieldName(
        fieldNamesArrSortedOnFieldNamePriorityOrFieldNameString,
        a
      );
      const sortByFieldNameB = getAppropriateFieldName(
        fieldNamesArrSortedOnFieldNamePriorityOrFieldNameString,
        b
      );

      return sortListClassic(
        a[sortByFieldNameA],
        b[sortByFieldNameB],
        sortDirection
      );
    });
  } else {
    sortedList = newList?.sort((a, b) => {
      return sortListNullUndefinedToTheEnd(a, b, sortDirection);
    });
  }

  return sortedList;
};

export const areAnyErrors = (errorsObj = {}) => {
  return Object.values(errorsObj).some((error) => error);
};

export const groupListByFieldName = (list, fieldName) => {
  if (!list.length) return [];
  const isListOfObjects = checkIfListOfObjects(list);
  if (!isListOfObjects) return list;
  const uniqueFieldNameValues = sortList(
    [...new Set(list?.map((listItem) => listItem?.[fieldName]))],
    ASC
  );

  const groupedList = uniqueFieldNameValues.map((fieldNameGroup) =>
    list?.filter((listItem) => listItem?.[fieldName] === fieldNameGroup)
  );
  return groupedList;
};

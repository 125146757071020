import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState, useEffect, useRef } from "preact/hooks";
import Collapse from "@mui/material/Collapse";
import PortCallCardMui from "./PortCallCardMui";

const PortcallVoyageGroup = ({
  groupIdx,
  groupArr,
  getPreviousPortcall,
  portcalls,
  setScheduledPortcalls,
  setArchivePortcalls,
  highlightedPortcallsFields,
  setHighlightedPortcallsFields,
  isAnyPortcallInEditMode,
  setIsAnyPortcallInEditMode,
  baseUrlToMainApp,
  archivedPortcall,
  isScheduled = true,
}) => {
  const { voyageNumber, portCalls } = groupArr ?? {};

  const voyageNo = voyageNumber
    ? `Voyage No ${voyageNumber}`
    : "No voyage number";

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  function sortPortCalls(portCalls) {
    const newArr = [...portCalls];
    return newArr.sort((a, b) => {
      if (a.isActive === false && b.isActive === true) return -1;
      if (a.isActive === true && b.isActive === false) return 1;

      const aEta = a.eta ? new Date(a.eta) : new Date(a.ata || 0);
      const bEta = b.eta ? new Date(b.eta) : new Date(b.ata || 0);

      return aEta - bEta;
    });
  }

  function sortArchivePortCalls(portCalls) {
    const newArr = [...portCalls];
    return newArr.sort((a, b) => {
      const aEtd = a.etd ? new Date(a.etd) : new Date(a.atd || 0);
      const bEtd = b.etd ? new Date(b.etd) : new Date(b.atd || 0);

      return aEtd - bEtd;
    });
  }

  const sortedPortCalls = isScheduled
    ? sortPortCalls(portCalls)
    : sortArchivePortCalls(portCalls);

  const containerRef = useRef(null);
  const firstActiveRef = useRef(null);
  let firstActiveSet = false;

  useEffect(() => {
    if (firstActiveRef.current && isScheduled) {
      containerRef.current.scrollLeft = firstActiveRef.current.offsetLeft - 150;
    }
  }, [isScheduled]);

  return (
    <Box>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginTop: "20px",
          marginBottom: "14px",
          padding: "0 20px 0 30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="20px"
        >
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontWeight: 200,
              fontSize: "20px",
              lineHeight: "24.2px",
              color: "layout.portcalls.voyageNoTitle",
            }}
          >
            {voyageNo}
          </Typography>
          <Box
            sx={{
              backgroundColor: "layout.portcalls.portcallsCount.background",
              color: "layout.portcalls.portcallsCount.color",
              padding: "5px",
              borderRadius: "2px",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16.94px",
            }}
          >
            {portCalls?.length}{" "}
            {portCalls?.length
              ? `port call${portCalls.length !== 1 ? "s" : ""}`
              : ""}
          </Box>
        </Stack>
        <IconButton
          className="circle"
          onClick={toggleCollapse}
          sx={{ rotate: isOpen ? "180deg" : "0", transition: "all 0.3s ease" }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Stack>
      <Collapse in={isOpen}>
        <Stack
          ref={containerRef}
          flexDirection="column"
          alignItems="flex-start"
          gap="20px"
          sx={{ overflowY: "auto", padding: "0 20px 0 30px" }}
        >
          {sortedPortCalls?.map((portcall, portcallIndex) => {
            let ref = null;
            let needToShowInPortBtn = false;
            if (portcall.isActive && !firstActiveSet) {
              ref = firstActiveRef;
              firstActiveSet = true;
              needToShowInPortBtn = true;
            }
            return (
              <PortCallCardMui
                needToShowInPortBtn={needToShowInPortBtn}
                propsRef={ref}
                key={portcall.portCallId}
                previousPortcall={
                  getPreviousPortcall
                    ? getPreviousPortcall(portcall, portcalls)
                    : null
                }
                portcall={portcall}
                setPortcalls={
                  archivedPortcall ? setArchivePortcalls : setScheduledPortcalls
                }
                portcallsList={portcalls}
                highlightedPortcallsFields={highlightedPortcallsFields}
                setHighlightedPortcallsFields={setHighlightedPortcallsFields}
                isAnyPortcallInEditMode={isAnyPortcallInEditMode}
                setIsAnyPortcallInEditMode={setIsAnyPortcallInEditMode}
                baseUrlToMainApp={baseUrlToMainApp}
                archivedPortcall={archivedPortcall}
                portcallIndex={portcallIndex}
                groupIdx={groupIdx}
              />
            );
          })}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default PortcallVoyageGroup;

import { createTheme } from "@mui/material/styles";
import useGeneratePalette from "./useGeneratePalette";
import { buttonConfig } from "../components/button/config";
import { iconButtonConfig } from "../components/icon-button/config";
import { alertConfig } from "../components/alert/config";
import { textfieldConfig } from "../components/textfield/config";
import { typographyConfig } from "../components/typography/config";
import { selectConfig } from "../components/select/config";
import { dividerConfig } from "../components/divider/config";
import { autocompleteConfig } from "../components/autocomplete/config";

const useMuiTheme = (mode) => {
  const palette = useGeneratePalette(mode);
  const theme = createTheme({
    palette: {
      ...palette,
    },
    components: {
      MuiButton: {
        ...buttonConfig,
      },
      MuiIconButton: {
        ...iconButtonConfig,
      },
      MuiAlert: {
        ...alertConfig,
      },
      MuiTextField: {
        ...textfieldConfig,
      },
      MuiTypography: {
        ...typographyConfig,
      },
      MuiSelect: {
        ...selectConfig,
      },
      MuiDivider: {
        ...dividerConfig,
      },
      MuiAutocomplete: {
        ...autocompleteConfig,
      },
    },
  });

  return theme;
};

export default useMuiTheme;

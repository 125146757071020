import { route, Router } from "preact-router";
// Code-splitting is automated for `routes` directory
import Login from "../routes/login/Login";
import Scheduled from "../routes/scheduled/Scheduled";
import Archive from "../routes/archive/Archive";
import { useEffect, useState } from "preact/hooks";
import {
  getLoginPayloadFromStorage,
  removeAuthTokenFromStorage,
} from "../utils/helpers/storageHelpers";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/footer";
import { AuthContext } from "../context/AuthContext";
import { ARCHIVE_PATH, SCHEDULED_PATH } from "../utils/constants/routePaths";
import WithThemeContext, { ThemeContext } from "../context/ThemeContext";
import { ServiceWorkerProvider } from "../context/ServiceWorkerContext";
import GlobalDataContextProvider from "../context/GlobalDataContextProvider";
import { Box } from "@mui/material";
import AutoLoggedToast from "./AutoLoggedToast/AutoLoggedToast";
import { BASE_URL } from "../utils/constants/api";

const App = () => {
  const {
    token: tokenFromStorage,
    chunkToken: chunkTokenFromStorage,
    vesselName: vesselNameFromStorage,
  } = getLoginPayloadFromStorage();

  const [token, setToken] = useState(tokenFromStorage);
  const [vesselName, setVesselName] = useState(vesselNameFromStorage);
  const [chunkToken, setChunkToken] = useState(chunkTokenFromStorage);
  const [autoLoggedOutBecauseOfToken, setAutoLoggedOutBecauseOfToken] =
    useState(false);

  const logout = () => {
    fetch(`${BASE_URL}Logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setToken(null);
    removeAuthTokenFromStorage();
  };
  useEffect(() => {
    document.body.classList.remove("body-wrapper");
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (token && autoLoggedOutBecauseOfToken) {
      setAutoLoggedOutBecauseOfToken(false);
    }

    if (token && pathname !== "/scheduled" && pathname !== "/archive") {
      window.location.replace(SCHEDULED_PATH);
    }
  }, [token]);

  return (
    <WithThemeContext>
      <ServiceWorkerProvider>
        <ThemeContext.Consumer>
          {([theme]) => (
            <Box
              sx={{
                background: theme === "dark" ? "#121212" : "white",
              }}
            >
              {token ? (
                <AuthContext.Provider
                  value={{
                    token,
                    chunkToken,
                    logout,
                    setAutoLoggedOutBecauseOfToken,
                  }}
                >
                  <GlobalDataContextProvider>
                    <Navbar
                      setToken={setToken}
                      vesselName={vesselName || "Vessel"}
                      theme={theme}
                    />
                    <main className="main" style={{}}>
                      <Router>
                        <Scheduled path={SCHEDULED_PATH} />
                        <Archive path={ARCHIVE_PATH} />
                      </Router>
                    </main>
                    <Footer />
                  </GlobalDataContextProvider>
                </AuthContext.Provider>
              ) : (
                <>
                  <AutoLoggedToast
                    autoLoggedOutBecauseOfToken={autoLoggedOutBecauseOfToken}
                    setAutoLoggedOutBecauseOfToken={
                      setAutoLoggedOutBecauseOfToken
                    }
                  />
                  <Login
                    setToken={setToken}
                    setChunkToken={setChunkToken}
                    setVesselName={setVesselName}
                  />
                </>
              )}
            </Box>
          )}
        </ThemeContext.Consumer>
      </ServiceWorkerProvider>
    </WithThemeContext>
  );
};

export default App;

import { BcError } from "../../../globalComponents/error/Error";
import { BcInput } from "../../../globalComponents/input/BcInput";
import { Box, Typography } from "@mui/material";

const ObservedDistanceSection = ({
  editMode,
  fieldName,
  value,
  setFieldValue,
  observedDistanceMandatory,
  currentPortcallErrors,
  inPort,
  errors,
}) => {
  return (
    <>
      {observedDistanceMandatory && (
        <Box>
          {!editMode && (
            <Typography variant="body1">
              {inPort
                ? "Observed distance in port"
                : "Observed distance from last port"}
            </Typography>
          )}

          {editMode ? (
            <Box mt="23px" width="236px">
              <BcInput
                type="text"
                value={value}
                appendLabel="miles"
                label={"Observed distance"}
                errorText={
                  errors?.observedInPortDistance || errors?.observedDistance
                    ? inPort
                      ? errors?.observedInPortDistance
                      : errors?.observedDistance
                    : ""
                }
                onInput={(value) => {
                  const formattedTargetValue = value
                    .replace(/[^0-9.,]/g, "")
                    .replace(/,/g, ".");
                  setFieldValue(fieldName, formattedTargetValue);
                }}
                useAutoStep
                autocomplete="off"
              />
            </Box>
          ) : (
            <Box mt="12px">
              {value == null || value === "" ? (
                <Typography variant="body2">Not provided</Typography>
              ) : (
                <Typography
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="body2" component="span" className="me-1">
                    {value}
                  </Typography>
                  <Typography variant="body3" component="span" mr="52px">
                    miles
                  </Typography>
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ObservedDistanceSection;

export const dividerPalette = (mode) => {
  if (mode === "dark") {
    return {
      color: "#575757",
    };
  }
  return {
    color: "#DDDDDD",
  };
};

export const dividerConfig = {
  styleOverrides: {
    root: ({ theme }) => ({
      "&::before, &::after": {
        borderColor: theme.palette.components.divider.color,
      },
    }),
  },
};

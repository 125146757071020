import { Box, Stack, Typography } from "@mui/material";
import { BcInput } from "../../../globalComponents/input/BcInput";
import { BcError } from "../../../globalComponents/error/Error";
import { earlyDelayArrivalReason } from "../../utils/constants";
import MultiSelect from "../../../globalComponents/select/MultiSelect";
import Chip from "@mui/material/Chip";

const RemarksSectionMui = ({
  editMode,
  remarks,
  setRemarks,
  isLoading,
  earlyDelayArrival,
  setEarlyDelayArrivalReason,
  setEarlyDelayArrivalComment,
  currentPortcallErrors,
}) => {
  const getLabelFromValue = (res) => {
    const reason = earlyDelayArrivalReason.find((item) => item.value === res);
    return reason ? reason.label : null;
  };
  return (
    <>
      <Box sx={{ width: "749px", ml: "10px" }}>
        {editMode ? (
          <BcInput
            onInput={(value) => setRemarks(value)}
            type="textarea"
            rows="8"
            label="Remark"
            value={remarks}
            isLoading={isLoading}
            nonResizableTextArea
            sx={{
              width: "100%",
            }}
          />
        ) : (
          <>
            <Typography variant="body1">Remark</Typography>
            <Typography variant="body2" mt="9px">
              {remarks ?? "No remark"}
            </Typography>
          </>
        )}
      </Box>
      <Stack width="49%" mb="20px">
        {editMode ? (
          <MultiSelect
            label="Delay Reason"
            values={earlyDelayArrival.reasons}
            options={earlyDelayArrivalReason}
            onChange={setEarlyDelayArrivalReason}
          />
        ) : (
          <Box mb="20px">
            <Typography variant="body1">Delay Reason</Typography>
            <Typography variant="body2" mt="9px">
              {earlyDelayArrival?.reasons?.length
                ? earlyDelayArrival.reasons.map((reason, idx) => {
                    return (
                      <Chip
                        label={getLabelFromValue(reason)}
                        key={reason}
                        sx={{
                          borderRadius: "2px",
                          background: (theme) =>
                            theme.palette.components.chip.default.background,
                          height: "20px",
                          ".MuiChip-label": {
                            px: "10px",
                            color: (theme) =>
                              theme.palette.components.chip.default.color,
                          },
                          ml: idx ? "10px" : 0,
                        }}
                      />
                    );
                  })
                : "-"}
            </Typography>
          </Box>
        )}
        {editMode ? (
          <Box mt="20px">
            <BcInput
              onInput={(value) => setEarlyDelayArrivalComment(value)}
              value={earlyDelayArrival.comment}
              isLoading={isLoading}
              nonResizableTextArea
              label="Delay Remark"
              sx={{
                width: "100%",
              }}
            />
          </Box>
        ) : (
          <Box mt="20px">
            <Typography variant="body1">Delay Remark</Typography>
            <Typography variant="body2" mt="9px">
              {earlyDelayArrival.comment ?? "-"}
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default RemarksSectionMui;

import { lightColors } from "../../utils/lightColors";
import { darken } from "@mui/material/styles";
import { darkColors } from "../../utils/darkColors";

export const textfieldPalette = (mode) => {
  if (mode === "dark") {
    return {
      outlined: {
        border: lightColors.primary["main-75"],
        color: lightColors.colors.white,
        focus: darkColors.primary.main,
        background: darkColors.dark.main,
        focusBorder: "#7CD6D3",
        error: {
          color: darkColors.colors.red,
          border: darkColors.colors.red,
        },
      },
    };
  }

  return {
    outlined: {
      border: lightColors.primary["main-75"],
      color: lightColors.dark.main,
      focus: lightColors.primary.main,
      background: lightColors.colors.white,
      focusBorder: "#7CD6D3",
      error: {
        color: lightColors.colors.red,
        border: lightColors.colors.red,
      },
    },
  };
};

export const textfieldConfig = {
  defaultProps: {
    size: "small",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      "& label": {
        color: theme.palette.components.textfield.outlined.color,
        fontFamily: "Inter",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: `6px`,
        color: theme.palette.components.textfield.outlined.color,
        backgroundColor: theme.palette.components.textfield.outlined.background,
        "& input": {
          color: theme.palette.components.textfield.outlined.color,
        },
        "& fieldset": {
          borderColor: theme.palette.components.textfield.outlined.border,
        },
        "&:hover fieldset": {
          borderColor: darken(
            theme.palette.components.textfield.outlined.border,
            0.1
          ),
        },
        "&.Mui-focused": {
          color: theme.palette.components.textfield.outlined.focus,
        },
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
        },
        "&.Mui-error": {
          color: theme.palette.components.textfield.outlined.error.color,
          borderColor: theme.palette.components.textfield.outlined.error.border,
          "& fieldset": {
            borderColor: `${theme.palette.components.textfield.outlined.error.border} !important`,
          },
        },
      },
    }),
  },
};

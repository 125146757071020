import { createContext } from "preact";
import { useContext, useEffect, useReducer } from "preact/hooks";
import { getAllPostRequests } from "../db";
import { Loader } from "../components/loader/Loader";

const _initialState = {
  count: null,
  isOnline: navigator.onLine,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_COUNT_QUEUE": {
      return {
        ...state,
        count: action.payload,
      };
    }
    case "NETWORK_MODE": {
      return {
        ...state,
        isOnline: action.payload,
      };
    }
    default:
      return state;
  }
};

const ServiceWorkerContext = createContext([
  _initialState,
  () => {
    return;
  },
]);

export const ServiceWorkerProvider = ({ children }) => {
  const context = useReducer(reducer, { ..._initialState });

  const initQueue = async () => {
    const res = await getAllPostRequests();
    context[1]({ type: "UPDATE_COUNT_QUEUE", payload: res.length });
  };

  useEffect(() => {
    initQueue();
  }, []);

  return (
    <ServiceWorkerContext.Provider value={context}>
      {context[0].count === null ? (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Loader displayText />
        </div>
      ) : (
        children
      )}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorkerContext = () => {
  const context = useContext(ServiceWorkerContext);

  if (!context) {
    throw new Error(
      "useServiceWorkerContext must be used within a ServiceWorkerContext"
    );
  }

  const [state, dispatch] = context;

  const updateCount = (count) => {
    dispatch({ type: "UPDATE_COUNT_QUEUE", payload: count });
  };

  const updateNetworkMode = (status) => {
    dispatch({ type: "NETWORK_MODE", payload: status });
  };

  return { state, updateCount, updateNetworkMode };
};

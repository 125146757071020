import Alert from "../../mui/components/alert/Alert";

const AutoLoggedToast = ({
  autoLoggedOutBecauseOfToken,
  setAutoLoggedOutBecauseOfToken,
}) => {
  return (
    <Alert
      severity="warning"
      message={
        autoLoggedOutBecauseOfToken
          ? "You have been logged out due to an invalid or expired token. Please log in again to continue."
          : null
      }
      onClose={() => setAutoLoggedOutBecauseOfToken(false)}
    />
  );
};

export default AutoLoggedToast;

import {darkColors} from "../../../mui/utils/darkColors";
import {lightColors} from "../../../mui/utils/lightColors";
import { darken, lighten } from '@mui/material/styles';
export const cardPalette = (mode) => {
    if(mode === "dark") {
        return  {
            header: {
                portcallId: darkColors.colors.tranquilTide,
                organization:darkColors.colors.tranquilTide,
                date: darkColors.colors.white,
                icon: darkColors.colors.tranquilTide,
                archive: {
                    background: "#454545",
                    border: "#575757",
                },
                active: {
                    background: darkColors.dark["main-50"],
                    border: darkColors.colors.graphiteGray,
                }
            },
            footer: {
                archive: {
                    background: lighten("#454545", 0.1),
                    border: "#575757",
                },
                active: {
                    background: darkColors.dark["main-50"],
                    border: darkColors.colors.graphiteGray,
                    button: {
                        color: darkColors.colors.white,
                    }
                }
            }
        }
    }

    return {
        header: {
            portcallId: lightColors.secondary.main,
            organization: lightColors.secondary.main,
            date: lightColors.dark.main,
            icon: lightColors.secondary["main-75"],
            archive: {
                background: "#EEEEEE",
                border: "#9A9A9A",
            },
            active: {
                background: lightColors.primary["main-5"],
                border: lightColors.primary["main-75"],
            }
        },
        footer: {
            archive: {
                background: "#F6F6F6",
                border: "#9A9A9A",
            },
            active: {
                background: lightColors.primary["main-15"],
                border: lightColors.primary["main-75"],
                button: {
                    color: lightColors.secondary.main,
                }
            }
        }
    }
}
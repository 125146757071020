import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ThankYou = ({setScreen, userEmail}) => {

    const goToSignIn = () => {
        setScreen('sign-in')
    }

    return (
        <Stack width='100%' flexDirection="column" justifyContent='center' alignItems='flex-start'>
            <Typography sx={{fontWeight: 500, fontSize: '24px', lineHeight: '29.05px', textAlign: 'left', color: 'layout.login.title', marginBottom: '34px'}} variant="h4" component="h4">
                Forgot Password
            </Typography>
            <Typography sx={{fontWeight: 400, fontSize: '20px', lineHeight: '24.2px', textAlign: 'left', color: 'layout.login.text', marginBottom: '10px'}} variant="h5" component="h5">
                Thank You!
            </Typography>
            <Typography sx={{fontWeight: 400, fontSize: '14px', lineHeight: '21px', textAlign: 'left', color: 'layout.login.text', marginBottom: '35px'}} variant="body1" component="body1">
                We've sent an e-mail to <br /> <b>{userEmail}</b>
            </Typography>
            <Button type='submit' variant='contained' fullWidth onClick={goToSignIn} sx={{padding: '13px 20px', maxHeight: '45px'}}>Back to login</Button>
        </Stack>
    )
}

export default ThankYou;
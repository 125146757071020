import { Box, Stack, Typography } from "@mui/material";
import { BcInput } from "../../../globalComponents/input/BcInput";
import {
  tugsOrderedReasonEnums,
  tugsSubReasonEnums,
} from "../../utils/constants";
import { BcSelect } from "../../../globalComponents/select/BcSelect";

const Tugs = ({ editMode, tugs, setTugs, errorsStateForTugs }) => {
  const generateTugTypeLabel = (tugType) => {
    return tugsOrderedReasonEnums?.find(({ value }) => value === tugType)
      ?.label;
  };
  const generateTugSubTypeLabel = (tugSubType) => {
    return tugsSubReasonEnums?.find(({ value }) => value === tugSubType)?.label;
  };

  const handleTugsChange = (value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const updatedTugs = { ...tugs, tugs: value };

      if (Number(value) === 0) {
        updatedTugs.tugsReason = "";
        updatedTugs.tugsSubReason = "";
      }

      setTugs(updatedTugs);
    }
  };

  const handleReasonChange = (value) => {
    const updatedTugs = { ...tugs, tugsReason: value };

    // Clear sub-reason if the reason value is invalid
    if (!value || value !== 2000) {
      updatedTugs.tugsSubReason = "";
    }

    setTugs(updatedTugs);
  };

  return (
    <Stack
      alignItems="start"
      width="100%"
      mb="15px"
      sx={{
        //   it can take two columns of the grid
        gridColumn: "span 2",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          width: "100%",
          gap: "20px",
        }}
      >
        {/* Tugs Input (one character number) */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            "&::before": {
              content: `"Tugs"`,
              fontSize: "12px",
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
              color: "components.typography.h3.color",
              position: "absolute",
              top: editMode ? "-10px" : "-23px",
              left: "0",
            },
          }}
        >
          {!editMode ? <Typography variant="body1">Tugs</Typography> : null}

          {editMode ? (
            <Box width="100px" mt="15px">
              <BcInput
                label="Tugs"
                type="text"
                value={tugs.tugs}
                onChange={(e) => handleTugsChange(e.target.value)}
                inputProps={{ maxLength: 1 }}
                errorText={errorsStateForTugs?.tugs}
              />
            </Box>
          ) : (
            <Typography variant="body2" mt="9px">
              {tugs.tugs ?? "-"}
            </Typography>
          )}
        </Box>

        {/* Reason Select */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {!editMode ? <Typography variant="body1">Reason</Typography> : null}

          {editMode ? (
            <Box width="177px" mt="15px">
              <BcSelect
                label="Reason"
                disabled={!tugs.tugs || !Number(tugs.tugs)}
                value={tugs.tugsReason}
                options={tugsOrderedReasonEnums}
                onChange={(value) => {
                  handleReasonChange(value);
                }}
                errorText={errorsStateForTugs?.tugsReason}
              />
            </Box>
          ) : (
            <Typography variant="body2" mt="9px">
              {generateTugTypeLabel(tugs.tugsReason) ?? "-"}
            </Typography>
          )}
        </Box>

        {/* SubReason Select */}
        {tugs.tugsReason === 2000 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {!editMode ? (
              <Typography variant="body1">Master reason</Typography>
            ) : null}

            {editMode ? (
              <Box width="177px" mt="15px">
                <BcSelect
                  label="Master Reason"
                  value={tugs.tugsSubReason}
                  options={tugsSubReasonEnums}
                  onChange={(value) => {
                    setTugs({ ...tugs, tugsSubReason: value });
                  }}
                  errorText={errorsStateForTugs?.tugsSubReason}
                />
              </Box>
            ) : (
              <Typography variant="body2" mt="9px">
                {generateTugSubTypeLabel(tugs.tugsSubReason) ?? "-"}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Tugs;

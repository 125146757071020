import { isBrowser } from "../helpers/storageHelpers";

export const isDevelop = () => {
  if (!isBrowser()) return;
  const originUrl = window.location.origin;

  if (originUrl.includes("localhost")) return true;

  return false;
};

const getBaseUrl = (returnWithoutApi = false) => {
  if (!isBrowser()) return;
  let baseUrl = "";

  const originUrl = window.location.origin;
  if (originUrl === "https://dev.onboard.beacon52.com") {
    baseUrl = "https://dev.hub.beacon52.com/";
  } else if (originUrl === "https://demo.onboard.beacon52.com") {
    baseUrl = "https://demo.hub.beacon52.com";
  } else if (originUrl === "https://onboard.beacon52.com") {
    baseUrl = "https://hub.beacon52.com";
  } else if (originUrl === "https://test.onboard.beacon52.com") {
    baseUrl = "https://test.hub.beacon52.com";
  } else if (
    originUrl === "https://nice-coast-0f328a903.5.azurestaticapps.net"
  ) {
    baseUrl = "https://beacon52-test-sec.azurewebsites.net";
  } else {
    baseUrl = "https://dev.hub.beacon52.com/";
  }

  if (returnWithoutApi) return baseUrl;
  return `${baseUrl}/api/`;
};

export const BASE_URL = `${getBaseUrl()}onboard/`;

export const FORGOT_PASSWORD_URL = `${getBaseUrl()}auth/forgotPassword`;

export const VALIDATE_TOKET = `${getBaseUrl(
  true
)}auth/validatesetpasswordtoken`;

export const SET_NEW_PASS = `${getBaseUrl()}auth/setpassword`;

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
};

import { useEffect, useState } from "preact/hooks";
import MuiAlert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const Alert = (props) => {
  const { message, onClose, ...otherProps } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        onClose();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, onClose]);

  return (
    <Collapse
      in={open}
      sx={{ position: "fixed", right: "20px", bottom: "20px", zIndex: 500 }}
    >
      <MuiAlert
        {...otherProps}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        variant="filled"
        icon={false}
        sx={{ minWidth: "344px" }}
      >
        <Box dangerouslySetInnerHTML={{ __html: message }} />
      </MuiAlert>
    </Collapse>
  );
};

export default Alert;

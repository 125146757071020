export enum BunkerFactorType {
  EmissionConversionEuMrv = 1000,
  EmissionConversionImoDcs = 2000,
  FuelCarbonIntesityEuReg = 3000,
  LowerCalorific = 4000,
}

export enum BunkerProductType {
  _380 = 500,
  HSFO = 1000,
  IFO = 1250,
  LCF = 1375,
  LCG = 1400,
  LCM = 1425,
  LNG = 1500,
  LSO = 1625,
  MDO = 1750,
  MGO = 2000,
  ULSFO = 3000,
  VLSFO = 4000,
}

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const BcSelect = ({
  value,
  options,
  disabled,
  isLoading,
  label,
  defaultValue,
  onChange,
  errorText,
  ...props
}) => {
  const selectedOption = options.find((option) => option.value === value);
  const filteredOptions = options.filter((option) => option.value !== value);

  return (
    <Autocomplete
      disabled={disabled || isLoading}
      options={filteredOptions}
      getOptionLabel={(option) => {
        return option.label;
      }}
      value={selectedOption || null}
      onChange={(e, newValue) => {
        onChange(newValue?.value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!errorText}
          helperText={errorText ? errorText : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={24} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

export { BcSelect };

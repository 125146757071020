import { lightColors } from "../../utils/lightColors";
import { darkColors } from "../../utils/darkColors";

export const buttonPalette = (mode) => {
  if (mode === "dark") {
    return {
      contained: {
        default: {
          background: darkColors.primary.main,
          color: darkColors.secondary.main,
          disabled: {
            background: darkColors.dark["main-15"],
            color: darkColors.colors.gray,
            border: darkColors.colors.gray,
          },
        },
      },
      outlined: {
        default: {
          background: darkColors.dark["main-25"],
          color: darkColors.colors.white,
          border: darkColors.dark["main-15"],
          hover: {
            background: darkColors.dark["main-15"],
            border: darkColors.dark["main-15"],
          },
        },
        error: {
          background: darkColors.dark["main-50"],
          color: darkColors.colors.blushingCoral,
          border: darkColors.dark["main-15"],
        },
      },
      text: {
        default: {
          color: darkColors.primary.main,
        },
        secondary: {
          color: darkColors.colors.white,
        },
      },
    };
  }

  return {
    contained: {
      default: {
        background: lightColors.primary.main,
        color: lightColors.secondary.main,
        disabled: {
          background: lightColors.colors.white,
          color: lightColors.colors.gray,
          border: lightColors.colors.gray,
        },
      },
    },
    outlined: {
      default: {
        background: lightColors.colors.white,
        color: lightColors.secondary.main,
        border: lightColors.colors.gray,
        hover: {
          background: lightColors.colors.gray,
          border: lightColors.colors.gray,
        },
      },
      error: {
        background: lightColors.colors.white,
        color: lightColors.colors.red,
        border: lightColors.colors.gray,
      },
    },
    text: {
      default: {
        color: lightColors.secondary.main,
      },
      secondary: {
        color: lightColors.secondary.main,
      },
    },
  };
};

export const buttonConfig = {
  styleOverrides: {
    root: () => ({
      borderRadius: `100px`,
      boxShadow: "none",
      fontFamily: "Inter",
      "&:hover": {
        boxShadow: "none",
      },
      textTransform: "none",
    }),
    contained: ({ theme }) => ({
      background: theme.palette.components.button.contained.default.background,
      color: theme.palette.components.button.contained.default.color,
      "&.Mui-disabled": {
        background:
          theme.palette.components.button.contained.default.disabled.background,
        color: theme.palette.components.button.contained.default.disabled.color,
      },
    }),
    outlined: ({ theme }) => ({
      background: theme.palette.components.button.outlined.default.background,
      color: theme.palette.components.button.outlined.default.color,
      border: "1px solid",
      borderRadius: "4px",
      borderColor: theme.palette.components.button.outlined.error.border,
      "&.MuiButton-colorError": {
        background: theme.palette.components.button.outlined.error.background,
        color: theme.palette.components.button.outlined.error.color,
        border: "1px solid",
        borderColor: theme.palette.components.button.outlined.error.border,
      },
      "&:hover": {
        background:
          theme.palette.components.button.outlined.default.hover.background,
        borderColor:
          theme.palette.components.button.outlined.default.hover.border,
      },
    }),
    text: ({ theme }) => ({
      color: theme.palette.components.button.text.default.color,
      textTransform: "uppercase",
    }),
  },
};

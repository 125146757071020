import { darkColors } from "../../mui/utils/darkColors";
import { lightColors } from "../../mui/utils/lightColors";

export const loaderPalette = (mode) => {
  if (mode === "dark") {
    return {
      baseColor: darkColors.colors.tranquilTide,
    };
  }
  return {
    baseColor: lightColors.secondary.main,
  };
};

import {
    removeAuthTokenFromStorage, removeUserEmailFromStorage,
    setLoginPayloadToLocalStorage, setUserEmailInStorage
} from "../../../../utils/helpers/storageHelpers";
import {useState} from "preact/hooks";
import {callLogin, getAuthStatus} from "../../../../utils/helpers/apiHelpers";
import {route} from "preact-router";
import {SCHEDULED_PATH} from "../../../../utils/constants/routePaths";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const SignIn = ({ setToken, setChunkToken, setVesselName, setErrorMessage, setScreen, userEmail, setUserEmail }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userPassword, setUserPassword] = useState(null);
    const [rememberEmail, setRememberEmail] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUserNameInput = (e) => {
        setUserEmail(e.target.value);
    };

    const handleUserPassword = (e) => {
        setUserPassword(e.target.value);
    };
    const handleRememberNameCheckbox = () => {
        setRememberEmail(!rememberEmail);
    };

    const goToForgotPassword = () => {
        setScreen('forgot-password')
    }

    const onLoginFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage("");
        try {
            const data = await callLogin({
                username: userEmail,
                password: userPassword,
            });
            setIsLoading(false);
            if (getAuthStatus(data.type) !== "Success") {
                return setErrorMessage(getAuthStatus(data.type));
            }
            removeAuthTokenFromStorage();
            setLoginPayloadToLocalStorage(
                data.payload.token,
                data.payload.chunkToken,
                data.payload.vesselName
            );
            setVesselName(data.payload.vesselName);
            setToken(data.payload.token);
            setChunkToken(data.payload.chunkToken);
            if (rememberEmail) {
                setUserEmailInStorage(userEmail);
            } else {
                removeUserEmailFromStorage();
            }
            route(SCHEDULED_PATH, true);
        } catch (err) {
            setErrorMessage(err);
            setIsLoading(false);
        }
    };


    return (
        <Stack width='100%' flexDirection="column" justifyContent='center' alignItems='flex-start'>
            <Typography sx={{fontWeight: 500, fontSize: '24px', lineHeight: '29.05px', textAlign: 'left', color: 'layout.login.title', marginBottom: '30px'}} variant="h4" component="h4">
                Login
            </Typography>
            <TextField label="Your email address" variant="outlined" fullWidth  type="email" onChange={handleUserNameInput} value={userEmail} />
            <TextField label="Your password" variant="outlined" fullWidth  type={showPassword ? 'text' : 'password'}
                       InputProps={{
                           endAdornment: (
                               <InputAdornment position="start">
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           onMouseDown={handleMouseDownPassword}
                                           edge="end"
                                       >
                                           {showPassword ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               </InputAdornment>
                           ),
                       }}
                       sx={{margin: '20px 0 30px'}} onChange={handleUserPassword} value={userPassword} />
            <FormControlLabel sx={{
                '& .MuiFormControlLabel-label': {color: 'layout.login.chekboxLabel', fontSize: '16px', lineHeight: '24px', fontWeight: 400}, '& .MuiTypography-root': {marginLeft: '10px'}
            }} control={<Checkbox checked={rememberEmail} onChange={handleRememberNameCheckbox} />} label="Remember email" />
            <Stack justifyContent='center' alignItems='center' flexDirection='column' gap='25px' mt='40px' width='100%'>
                <Button type='submit' variant='contained' fullWidth disabled={isLoading} onClick={onLoginFormSubmit} sx={{padding: '13px 20px', maxHeight: '45px'}}>Submit</Button>
                <Button type='button' variant='text' fullWidth disabled={isLoading} onClick={goToForgotPassword}>Forgot password?</Button>
            </Stack>
        </Stack>
    );
}




export default SignIn;
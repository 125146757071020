import { Box, Stack } from "@mui/material";

const StatusMode = ({ isOnline, count }) => {
  const status =
    count === 0
      ? isOnline
        ? "online"
        : "offline"
      : `${count} ${count > 1 ? "requests" : "request"} in the queue`;

  const color =
    count === 0
      ? isOnline
        ? "layout.navbar.statusMode.online"
        : "layout.navbar.statusMode.offline"
      : "layout.navbar.statusMode.queued";

  return (
    <Stack
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap="9px"
      sx={{
        color: "layout.navbar.statusMode.color",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19.36px",
        fontFamily: "Inter",
      }}
    >
      <Box
        sx={{
          width: "14px",
          height: "14px",
          backgroundColor: color,
          borderRadius: "100%",
        }}
      />
      {status}
    </Stack>
  );
};

export default StatusMode;

import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { Box } from "@mui/material";
import {
  MEDIUM_STYLE,
  SMALL_STYLE,
} from "../../utils/constants/styleConstants";

const Loader = ({ type, displayText = false }) => {
  const getIconSize = () => {
    switch (type) {
      case MEDIUM_STYLE:
        return 32;
      case SMALL_STYLE:
        return 16;
      default:
        return 64;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{ flexDirection: "column" }}
    >
      <SyncRoundedIcon
        sx={{
          fontSize: getIconSize(),
          color: "components.loader.baseColor",
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(-360deg)",
            },
          },
        }}
      />
      {displayText && (
        <Box
          sx={{
            marginTop: "20px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "components.loader.baseColor",
          }}
        >
          Loading content
        </Box>
      )}
    </Box>
  );
};

export { Loader };

import { lightColors } from "../../utils/lightColors";
import {darkColors} from "../../utils/darkColors";


export const iconButtonPalette = (mode) => {
  if(mode === "dark") {
    return {
      default: {
        background: 'transparent',
        color: darkColors.colors.white,
        hover: {
          background: lightColors.primary['main-75'],
        }
      },
      inherit: {
        background: 'transparent',
        color: lightColors.colors.white,
        hover: {
          background: 'transparent',
        }
      }
    }
  }
  return {
    default: {
      background: 'transparent',
      color: lightColors.secondary.main,
      hover: {
        background: lightColors.primary['main-75'],
      }
    },
    inherit: {
      background: 'transparent',
      color: lightColors.colors.white,
      hover: {
        background: 'transparent',
      }
    }
  }
}


export const iconButtonConfig = {
  styleOverrides: {
    root:  ({ theme }) => ({
      borderRadius: `${theme.shape.borderRadius.xs}px`,
      boxShadow: "none",
      padding: '6px',
      background: theme.palette.components.iconButton.default.background,
      color: theme.palette.components.iconButton.default.color,
      width: 'fit-content',
      "&:hover": {
        boxShadow: "none",
        background: theme.palette.components.iconButton.default.hover.background
      },
      "&.circle": {
        borderRadius: '100%'
      },
      "&.MuiIconButton-colorInherit": {
        background: theme.palette.components.iconButton.inherit.background,
        color: theme.palette.components.iconButton.inherit.color,
      }
    }),
  },
};
import {darkColors} from "../../mui/utils/darkColors";
import {lightColors} from "../../mui/utils/lightColors";

export const navbarPalette = (mode) => {
    if(mode === "dark") {
        return  {
            background: darkColors.dark['main-25'],
            badge: {
                background: darkColors.colors.white,
                color: darkColors.secondary.main
            },
            navLink: {
                active: {
                    background: darkColors.dark.main,
                    color: darkColors.colors.white
                },
                default: {
                    background: darkColors.dark['main-25'],
                    color: darkColors.colors.white
                }
            },
            statusMode: {
                online: darkColors.colors.status.online,
                offline: darkColors.colors.status.offline,
                queued: darkColors.colors.status.queued,
                color: darkColors.colors.white
            },
            toogleMode: {
                color: darkColors.colors.white
            },
            profile: {
                color: darkColors.colors.white,
                icon: darkColors.primary["main-75"]
            },
            dropdown: {
                background: darkColors.dark['main-25'],
                color: darkColors.colors.white,
                border: darkColors.dark["main-50"],
                hover: darkColors.dark["main-50"]
            }
        }
    }

    return {
        background: lightColors.secondary.main,
        badge: {
            background: lightColors.colors.white,
            color: lightColors.secondary.main
        },
        navLink: {
            active: {
                background: lightColors.colors.white,
                color: lightColors.secondary.main
            },
            default: {
                background: lightColors.secondary.main,
                color: lightColors.colors.white
            }
        },
        statusMode: {
            online: lightColors.colors.status.online,
            offline: lightColors.colors.status.offline,
            queued: lightColors.colors.status.queued,
            color: lightColors.colors.white
        },
        toogleMode: {
          color: lightColors.colors.white
        },
        profile: {
            color: lightColors.colors.white,
            icon: lightColors.primary["main-75"]
        },
        dropdown: {
            background: lightColors.colors.white,
            color: lightColors.secondary.main,
            border: lightColors.colors.lightGray
        }
    }
}
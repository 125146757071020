import { openDB } from "idb";

const DB_NAME = "onboard-cache";
const STORE_NAME = "post-requests";
export const BUNKER_FACTORS_SETTINGS = "bunker-factors-settings";
export const SCHEDULED_PORTCALLS = "scheduled-portcalls";
export const ARCHIVE_PORTCALLS = "archive-portcalls";

function updateObjectInArray(objToUpdate, array) {
  return array[0].map((item) =>
    item.portCallId === objToUpdate.portCallId ? objToUpdate : item
  );
}
const dbPromise = openDB(DB_NAME, 5, {
  // Updated version to 2
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, { autoIncrement: true });
    }
    if (!db.objectStoreNames.contains(SCHEDULED_PORTCALLS)) {
      db.createObjectStore(SCHEDULED_PORTCALLS, { autoIncrement: true });
    }
    if (!db.objectStoreNames.contains(ARCHIVE_PORTCALLS)) {
      db.createObjectStore(ARCHIVE_PORTCALLS, { autoIncrement: true });
    }
    if (!db.objectStoreNames.contains(BUNKER_FACTORS_SETTINGS)) {
      db.createObjectStore(BUNKER_FACTORS_SETTINGS, { autoIncrement: true });
    }
  },
});

export const addPostRequestToStore = async (data) => {
  const db = await dbPromise;

  return db.add(STORE_NAME, data);
};

export const getPostRequestCount = async () => {
  const db = await dbPromise;

  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  const count = await store.count();
  return count;
};

export const getAllPostRequests = async () => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, "readonly");
  const store = tx.objectStore(STORE_NAME);
  const keys = await store.getAllKeys();
  const values = await store.getAll();
  return values.map((value, index) => {
    return {
      id: keys[index],
      ...value,
    };
  });
};

export const deletePostRequestFromStore = async (id) => {
  const db = await dbPromise;
  return db.delete(STORE_NAME, id);
};

//GET REQUEST
export const addPortcallsToStore = async (data, dbName) => {
  const db = await dbPromise;
  return db.add(dbName, data);
};

export const deleteAllFromScheduledPortcalls = async () => {
  const db = await dbPromise;
  const tx = db.transaction(SCHEDULED_PORTCALLS, "readwrite");
  const store = tx.objectStore(SCHEDULED_PORTCALLS);
  await store.clear();
  return tx.complete;
};

export const updateScheduledPortcall = async (portcall) => {
  const db = await dbPromise;
  const tx = db.transaction(SCHEDULED_PORTCALLS, "readonly");
  const store = tx.objectStore(SCHEDULED_PORTCALLS);
  const allData = await store.getAll();
  await deleteAllFromScheduledPortcalls();
  const updatedData = updateObjectInArray(portcall, allData);
  await addPortcallsToStore(updatedData, SCHEDULED_PORTCALLS);
};

export const deleteAllFromArchivePortcalls = async () => {
  const db = await dbPromise;
  const tx = db.transaction(ARCHIVE_PORTCALLS, "readwrite");
  const store = tx.objectStore(ARCHIVE_PORTCALLS);
  await store.clear();
  return tx.complete;
};

export const getAllFromScheduledPortcalls = async () => {
  const db = await dbPromise;
  const tx = db.transaction(SCHEDULED_PORTCALLS, "readonly");
  const store = tx.objectStore(SCHEDULED_PORTCALLS);
  const allData = await store.getAll();
  return allData;
};

export const getAllFromArchivePortcalls = async () => {
  const db = await dbPromise;
  const tx = db.transaction(ARCHIVE_PORTCALLS, "readonly");
  const store = tx.objectStore(ARCHIVE_PORTCALLS);
  const allData = await store.getAll();
  return allData;
};

export const addBunkerFactorsSettingsToStore = async (data) => {
  const db = await dbPromise;
  return db.add(BUNKER_FACTORS_SETTINGS, data);
};

export const deleteAllFromBunkerFactorsSettings = async () => {
  const db = await dbPromise;
  const tx = db.transaction(BUNKER_FACTORS_SETTINGS, "readwrite");
  const store = tx.objectStore(BUNKER_FACTORS_SETTINGS);
  await store.clear();
  return tx.complete;
};

export const updateBunkerFactorsSettings = async (
  bunkerFactorsSettingFactorValues,
  bunkerFactorsSettingId,
  callback
) => {
  const db = await dbPromise;
  const tx = db.transaction(BUNKER_FACTORS_SETTINGS, "readonly");
  const store = tx.objectStore(BUNKER_FACTORS_SETTINGS);
  const allData = await store.getAll();
  await deleteAllFromBunkerFactorsSettings();

  const updatedData = allData[0].map((singleBunkerFactor) =>
    singleBunkerFactor.id === bunkerFactorsSettingId
      ? {
          ...singleBunkerFactor,
          factorValues: bunkerFactorsSettingFactorValues,
        }
      : singleBunkerFactor
  );

  await addBunkerFactorsSettingsToStore(updatedData);
  callback();
};

export const getAllFromBunkerFactorsSettings = async () => {
  const db = await dbPromise;
  const tx = db.transaction(BUNKER_FACTORS_SETTINGS, "readonly");
  const store = tx.objectStore(BUNKER_FACTORS_SETTINGS);
  const allData = await store.getAll();
  return allData;
};

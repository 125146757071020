export const dateTimeToStringWithoutSeconds = (date) => {
  const formattedDate = date
    .toLocaleString("en-US", {
      hour12: false,
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace("at", ",");
  const formattedDateArr = formattedDate.split(",");
  const month = formattedDateArr[0].split(" ")[0].slice(0, 3);
  const day = formattedDateArr[0].split(" ")[1];
  const year = formattedDateArr[1].trim();
  const time = formattedDateArr[2].trim();
  const formattedTime =
    time.slice(0, 2) === "24" ? `00:${time.slice(3, 5)}` : time;
  const finalDateString = `${day} ${month} ${year}, ${formattedTime}`;
  return finalDateString;
};

function toISOLocal(d) {
  var z = (n) => ("0" + n).slice(-2);
  var zz = (n) => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
}

export const correctDateTimezone = (date) => {
  if (!date) return date;
  return new Date(date)?.toISOString().slice(0, 16);
};

export const correctLocaleDateTimezone = (date) => {
  if (!date) return date;
  return toISOLocal(new Date(date)).slice(0, 16);
};

export const getUserTimezone = (date) => {
  if (!date) return;
  return new Date(date).getTimezoneOffset();
};

export const correctDateTimezoneReturnDateObj = (date) => {
  if (!date) return;
  const timezoneOffset = new Date().getTimezoneOffset();
  return new Date(new Date(date) - timezoneOffset * 60 * 1000);
};

export const formatDate = (date) => {
  if (date) return dateTimeToStringWithoutSeconds(new Date(date));
  return "Not provided";
};

export const lightColors = {
    primary: {
        main: '#24BAB6',
        'main-75': '#7CD6D3',
        'main-50': '#B7E8E7',
        'main-25': '#EBF9F9',
        'main-15': '#F6FCFC',
        'main-5': '#D3F1F0'
    },
    secondary: {
        main: '#031954',
        'main-75': '#354776',
        'main-50': '#6E7DA4',
        'main-25': '#CDD1DD'
    },
    dark: {
        main: '#515151',
        'main-75': '#797979',
        'main-50': '#DDDDDD',
        'main-25': '#EFEFEF',
        'main-15': '#3f3f3f'
    },
    colors: {
        white: '#FFFFFF',
        red: '#F14549',
        orange: '#F9703B',
        bluish: '#35477680',
        gray: '#DDDDDD',
        darkGray: '#647295',
        acidGreen: '#1BC641',
        whisperingMist: '#FCFCFC',
        crystalCloud: '#F3F3F3',
        darkgreen: '#00424E',
        pink: '#FE8EE3',
        warning: 'rgba(255, 168, 38, 1)',
        lightGray: '#D6D6D6',
        alerts: {
            green: "#1BC641",
            yellow: "#FFA826",
            red: "#F14549"
        },
        status: {
            online: '#1BC641',
            offline: '#F14549',
            queued:'#FFA826'
        },
    }
};

import { lightColors } from "../../utils/lightColors";

export const tabPalette = (mode) => {
  if (mode === "dark") {
    return {
      color: lightColors.colors.white,
    };
  }
  return {
    color: lightColors.secondary.main,
  };
};

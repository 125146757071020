export const isBrowser = () => typeof window !== "undefined";

export const LOCAL_STORAGE_NAME = "onBoardLoginPayload";

const isPrimitiveType = (variable) => {
  const typeOfVariable = typeof variable;
  const isVariablePrimitive =
    typeOfVariable === "string" ||
    typeOfVariable === "number" ||
    typeOfVariable === "boolean" ||
    typeOfVariable === "null" ||
    typeOfVariable === "undefined" ||
    typeOfVariable === "symbol";
  return isVariablePrimitive;
};

const getItemFromStorage = (itemKey = LOCAL_STORAGE_NAME) => {
  if (!isBrowser()) return;
  const itemFromLocalStorage = window.localStorage.getItem(itemKey);
  return itemFromLocalStorage;
};
const setItemToStorage = (itemKey = LOCAL_STORAGE_NAME, itemValue) => {
  if (!isBrowser()) return;

  window.localStorage.setItem(
    itemKey,
    isPrimitiveType(itemValue) ? itemValue : JSON.stringify(itemValue)
  );
};

const removeItemFromStorage = (itemKey = LOCAL_STORAGE_NAME) => {
  if (!isBrowser()) return;
  window.localStorage.removeItem(itemKey);
};

export const setLoginPayloadToLocalStorage = (
  token,
  chunkToken,
  vesselName
) => {
  setItemToStorage(LOCAL_STORAGE_NAME, { token, chunkToken, vesselName });
};

export const getLoginPayloadFromStorage = () => {
  const loginPayload = getItemFromStorage();

  return loginPayload ? JSON.parse(loginPayload) : {};
};

export const removeAuthTokenFromStorage = () => {
  removeItemFromStorage();
};

export const setUserEmailInStorage = (userEmail) => {
  setItemToStorage("userEmail", userEmail);
};

export const removeUserEmailFromStorage = () => {
  removeItemFromStorage("userEmail");
};

export const getUserEmailFromStorage = () => {
  return getItemFromStorage("userEmail");
};

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useContext } from "preact/hooks";
import { AuthContext } from "../../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";

export const CardActionsButtons = ({
  editMode,
  isLoading,
  isSubmitFormBtnDisabled,
  isInPortButtonShown,
  isAnyPortcallInEditMode,
  cancelClickHandler,
  updateClickHandler,
  portCallId,
  baseUrlToMainApp,
  isNoChangesBtnDisabled,
}) => {
  const { token: authToken, chunkToken } = useContext(AuthContext);

  return (
    <Box display="flex" gap="10px">
      {isInPortButtonShown && (
        <Button
          variant="outlined"
          onClick={() =>
            window.open(
              `${baseUrlToMainApp}/portcalls/view?id=${portCallId}&authToken=${authToken}&chunkToken=${chunkToken}`,
              "_blank"
            )
          }
          sx={{ textTransform: "none" }}
        >
          In port
        </Button>
      )}

      {editMode ? (
        <Box display="flex" gap="10px">
          <Button
            variant="outlined"
            color="error"
            onClick={cancelClickHandler}
            disabled={isLoading}
            sx={{
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: "6px !important",
            }}
            disabled={isSubmitFormBtnDisabled || isLoading}
          >
            Update
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: "6px !important",
            }}
            disabled={isNoChangesBtnDisabled || isLoading}
          >
            No changes
          </Button>
        </Box>
      ) : (
        <IconButton
          onClick={updateClickHandler}
          disabled={isAnyPortcallInEditMode || isLoading}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

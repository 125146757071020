export const chipPalette = (mode) => {
  if (mode === "dark") {
    return {
      default: {
        background: "#3F3F3F",
        color: "#fff",
      },
      multiselect: {
        background: "#24bab6",
        color: "#121212",
      },
    };
  }
  return {
    default: {
      background: "#D3F1F0",
      color: "#354776",
    },
    multiselect: {
      background: "#d3f1f0",
      color: "#031954",
    },
  };
};

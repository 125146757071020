import { createContext } from "preact";
import { useReducer, useEffect } from "preact/hooks";
import MuiProvider from "../mui/utils/MuiProvider";

export const ThemeContext = createContext();

const themeReducer = (theme) => (theme === "light" ? "dark" : "light");

const initialState = JSON.parse(localStorage.getItem("theme")) || "light";

const WithThemeContext = ({ children }) => {
  const [theme, dispatch] = useReducer(themeReducer, initialState);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, dispatch]}>
      <MuiProvider mode={theme} >
        {children}
      </MuiProvider>
    </ThemeContext.Provider>
  );
};

export default WithThemeContext;

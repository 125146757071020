import { ArrowContainer, Popover } from "react-tiny-popover";

interface IBcCustomMultipleSelector {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  popoverTarget: any;
  children: any;
  onClosePopover?: any;
  positions?: "end" | "bottom" | "left" | "right" | "top"[];
}

export const BcPopover = ({
  isOpen,
  setIsOpen,
  popoverTarget,
  children,
  onClosePopover,
  positions,
}: IBcCustomMultipleSelector) => {
  return (
    <Popover
      isOpen={isOpen}
      positions={positions ? positions : ["bottom", "end"]} // if you'd like, you can limit the positions
      padding={10} // adjust padding here!
      reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
      onClickOutside={() => {
        setIsOpen(false);
        if (onClosePopover) onClosePopover();
      }} // handle click events outside of the popover/target here!
      content={(
        { position, childRect, popoverRect } // you can also provide a render function that injects some useful stuff!
      ) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"#e6e6e6"}
          arrowSize={10}
          arrowStyle={{}}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          {children}
        </ArrowContainer>
      )}
    >
      {popoverTarget}
    </Popover>
  );
};

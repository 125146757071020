import {
  API_METHODS,
  BASE_URL,
  FORGOT_PASSWORD_URL,
  SET_NEW_PASS,
  VALIDATE_TOKET,
} from "../constants/api";

export async function callLogin({ username, password }) {
  const data = {
    method: API_METHODS.POST,
    body: JSON.stringify({
      username,
      password,
    }),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(`${BASE_URL}login`, data);
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    throw e;
  }
}

export const callForgotPassword = async ({ username }) => {
  try {
    const data = {
      method: API_METHODS.POST,
      body: JSON.stringify({
        username,
        isOnBoard: true,
      }),
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(`${FORGOT_PASSWORD_URL}`, data);
    const responseData = await response.json();
    return responseData;
  } catch (err) {
    throw err;
  }
};

export async function validateSetPasswordToken(token) {
  const data = {
    method: "GET",
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(VALIDATE_TOKET, data);
  return response;
}

export async function callSetPassword(token, password) {
  const data = {
    method: "POST",
    body: JSON.stringify({
      password,
    }),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(SET_NEW_PASS, data).then((x) => x.json());
  return response;
}

export const getAuthStatus = (type) => {
  if (type === 750)
    return "Your account has been deactivated due to inactivity";
  if (type === 1000) return "Expired";
  if (type === 2000) return "Failed";
  if (type === 3000) return "Invalid credentials";
  if (type === 4000) return "Invalid inputs";
  if (type === 5000)
    return "Unknown user, please check your username (email) and try again";
  if (type === 5500) return "No roles";
  if (type === 6000) return "Success";
  if (type === 8000) return "Wrong user type.";
  return "Failed";
};

import dayjs, { Dayjs } from "dayjs";
import { getDayInYear } from "../../components/datepicker/BcDateTimePicker";

export function changeDateMonth(date: Dayjs, isNextMonth: boolean): Dayjs {
  if (date.month() === 0 && !isNextMonth) {
    return date.set("year", date.year() - 1).set("month", 11);
  }

  if (date.month() === 11 && isNextMonth) {
    return date.set("year", date.year() + 1).set("month", 0);
  }

  return date.add(isNextMonth ? 1 : -1, "month");
}

export const changeDateYear = (date: Dayjs, year: number) => {
  return date.set("year", year);
};

export interface ICalendarCell {
  text: string;
  value: Dayjs;
}

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear; i >= 1900; i--) {
    years.push(i);
  }
  return years;
};

const getCalendarCells = (date: Dayjs): ICalendarCell[] => {
  const daysArray = new Array(date.daysInMonth()).fill(1);
  const calendarCells: ICalendarCell[] = [];

  const prepareCell = (date: Dayjs, dayNumber: number) => {
    return {
      text: String(dayNumber),
      value: date.clone().set("date", dayNumber),
    };
  };
  daysArray.forEach((_, i) => {
    calendarCells.push(prepareCell(date, i + 1));
  });

  // 0 - Sunday, 6 - Saturday
  const MONDAY = 1;
  const SUNDAY = 7;
  const dayOfWeekForFirstOfMonth = calendarCells[0].value.day() || SUNDAY;

  const lastMonth = date.subtract(1, "month");
  for (let i = 0; i < dayOfWeekForFirstOfMonth - MONDAY; i++) {
    calendarCells.unshift(prepareCell(lastMonth, lastMonth.daysInMonth() - i));
  }

  const cellsToAdd =
    calendarCells.length <= 35
      ? 35 - calendarCells.length
      : 42 - calendarCells.length;

  const nextMonth = date.add(1, "month");
  for (let i = 0; i < cellsToAdd; i++) {
    calendarCells.push(prepareCell(nextMonth, i + 1));
  }

  return calendarCells;
};

export function getCalendarRows(date: Dayjs): Array<ICalendarCell[]> {
  const cells = getCalendarCells(date);
  const rows: Array<ICalendarCell[]> = [];

  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7));
  }

  return rows;
}

export const defaultUnselectedDateLabel = "Select...";

export enum DisplayDateFormatEnum {
  WITH_TIME_FORMAT = "D MMM YYYY, HH:mm",
  WITHOUT_TIME_FORMAT = "D MMM YYYY",
}

export const WITH_TIME_FORMAT = "D MMM YYYY, HH:mm";
export const WITHOUT_TIME_FORMAT = "D MMM YYYY";

export const NEW_DATE_FORMAT = "DD/MM HH:mm";
export const NEW_WITHOUT_TIME_FORMAT = "DD/MM/YY";
export const bcDateTimeFormat = (
  date?: Dayjs | string,
  displayDateFormat: DisplayDateFormatEnum = DisplayDateFormatEnum.WITH_TIME_FORMAT,
  noDateLabel?: string = defaultUnselectedDateLabel
) => {
  if (!date) return noDateLabel;
  const formattedDate = dayjs(date).local().format(displayDateFormat);
  return formattedDate;
};
export const bcDateTimeUTCFormat = (
  date?: Dayjs | string,
  displayDateFormat: DisplayDateFormatEnum = DisplayDateFormatEnum.WITH_TIME_FORMAT,

  noDateLabel = defaultUnselectedDateLabel
) => {
  if (!date) return noDateLabel;
  const dayjsDate = generateDayjsDate(date, true);

  return dayjsDate.format(displayDateFormat);
};

export const formatTime = (time) => {
  return time < 10 ? "0" + time : time;
};
export const getTimeValue = (dateTime: Dayjs | undefined) => {
  if (!dateTime) return;
  const hrs = dateTime.hour();
  const mins = dateTime.minute();
  const formattedHrs = formatTime(hrs);
  const formattedMins = formatTime(mins);

  return `${formattedHrs}:${formattedMins}`;
};

export const getNoOfDayInYear = (dateTime: Dayjs | undefined) => {
  if (!dateTime) return;
  const noOfDateInYear = getDayInYear(dateTime);
  return noOfDateInYear;
};

export const getYear = (dateTime: Dayjs | undefined) => {
  if (!dateTime) return;
  return dateTime.year();
};

export const generateDayjsDate = (date?: string | Dayjs, utc?: boolean) => {
  if (!date) return date;
  const dateArg = date instanceof Date ? dayjs(date) : date;
  const dayjsUtcFromDateUtc = dayjs(dateArg).utc();
  const dayjsUtcFromDateLocal = dayjs(dateArg).utc(true); //just add UTC to current time
  const dayjsLocal = dayjs(dateArg);

  const isDateArgUtc =
    (typeof dateArg === "string" && dateArg.includes("Z")) ||
    (dayjs.isDayjs(dateArg) && dateArg.isUTC());

  let generatedDayJsDate;

  if (isDateArgUtc) {
    if (utc) {
      generatedDayJsDate = dayjsUtcFromDateUtc;
    } else {
      generatedDayJsDate = dayjsLocal;
    }
  } else {
    if (utc) {
      generatedDayJsDate = dayjsUtcFromDateLocal;
    } else {
      generatedDayJsDate = dayjsLocal;
    }
  }

  return generatedDayJsDate;
};

const getNoOfDayInYearAndYear = (date: Dayjs) => {
  const dateDayInYear = getNoOfDayInYear(date);
  const dateYear = getYear(date);
  return { dateDayInYear, dateYear };
};

export const isFirstDateBiggerOrEqualThanSecond = (
  date1: Dayjs,
  date2: Dayjs
) => {
  const { dateDayInYear: date1DayInYear, dateYear: date1Year } =
    getNoOfDayInYearAndYear(date1);
  const { dateDayInYear: date2DayInYear, dateYear: date2Year } =
    getNoOfDayInYearAndYear(date2);

  if (date1Year > date2Year) {
    return true;
  }
  if (date1Year === date2Year) {
    if (date1DayInYear >= date2DayInYear) {
      return true;
    }
  }
  return false;
};
export const isFirstDateBiggerThanSecond = (
  date1Arg: Dayjs | string,
  date2Arg: Dayjs | string
) => {
  const date1 = typeof date1Arg === "string" ? dayjs(date1Arg) : date1Arg;
  const date2 = typeof date2Arg === "string" ? dayjs(date2Arg) : date2Arg;
  const { dateDayInYear: date1DayInYear, dateYear: date1Year } =
    getNoOfDayInYearAndYear(date1);
  const { dateDayInYear: date2DayInYear, dateYear: date2Year } =
    getNoOfDayInYearAndYear(date2);

  if (date1Year > date2Year) {
    return true;
  }
  if (date1Year === date2Year) {
    if (date1DayInYear > date2DayInYear) {
      return true;
    }
  }
  return false;
};

export const formatSelectedDateForDisplaying = (
  withTimeInput,
  utc,
  withRange,
  selectedDate,
  selectedEndDate,
  placeholderText
) => {
  let selectedDateLabelToDisplay = "";
  let endDateLabelToDisplay = "";
  if (withTimeInput) {
    if (utc) {
      selectedDateLabelToDisplay = bcDateTimeUTCFormat(
        selectedDate,
        DisplayDateFormatEnum.WITH_TIME_FORMAT,
        ""
      );
      endDateLabelToDisplay = bcDateTimeUTCFormat(
        selectedEndDate,
        DisplayDateFormatEnum.WITH_TIME_FORMAT,
        ""
      );
    } else {
      selectedDateLabelToDisplay = bcDateTimeFormat(
        selectedDate,
        DisplayDateFormatEnum.WITH_TIME_FORMAT,
        ""
      );
      endDateLabelToDisplay = bcDateTimeFormat(
        selectedEndDate,
        DisplayDateFormatEnum.WITH_TIME_FORMAT,
        ""
      );
    }
  } else {
    if (utc) {
      selectedDateLabelToDisplay = bcDateTimeUTCFormat(
        selectedDate,
        DisplayDateFormatEnum.WITHOUT_TIME_FORMAT,
        ""
      );
      endDateLabelToDisplay = bcDateTimeUTCFormat(
        selectedEndDate,
        DisplayDateFormatEnum.WITHOUT_TIME_FORMAT,
        ""
      );
    } else {
      selectedDateLabelToDisplay = bcDateTimeFormat(
        selectedDate,
        DisplayDateFormatEnum.WITHOUT_TIME_FORMAT,
        ""
      );
      endDateLabelToDisplay = bcDateTimeFormat(
        selectedEndDate,
        DisplayDateFormatEnum.WITHOUT_TIME_FORMAT,
        ""
      );
    }
  }

  const dateLabelToDisplay = withRange
    ? `${selectedDateLabelToDisplay} - ${endDateLabelToDisplay}`
    : selectedDateLabelToDisplay;

  return dateLabelToDisplay;
};

export const isSameDates = (date1: string | Dayjs, date2: string | Dayjs) => {
  const date1Arg =
    date1 instanceof Date ? dayjs(date1.toISOString()) : dayjs(date1);
  const date2Arg =
    date2 instanceof Date ? dayjs(date2.toISOString()) : dayjs(date2);
  if (!date1 && !date2) return true;
  return date1Arg.isSame(date2Arg);
};

export const getMinutesDifference = (
  date1: Dayjs | string | Date,
  date2: Dayjs | string | Date
) => {
  const date1Arg = dayjs(date1);
  const date2Arg = dayjs(date2);
  return date1Arg.diff(date2Arg, "minute");
};

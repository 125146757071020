import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {BcDateTimePicker} from "../../../datepicker/BcDateTimePicker";
import {bcDateTimeUTCFormat, NEW_DATE_FORMAT} from "../../../../utils/helpers/dateTimePickerHelpers";
import {useMemo} from "preact/hooks";
import dayjs from "dayjs";
const ReportingEtaField = ({portCallEtaResult,editMode, isNeedToShow}) => {
    const reportingEtaContent = useMemo(() => {
        return (
            <>
                {!editMode && (
                    <Typography variant="body1">
                        Reporting ETA
                    </Typography>
                )}

                {editMode ? (
                    <>
                        <Box mt="23px" width="236px">
                            <BcDateTimePicker
                                label={"Reporting ETA"}
                                withTimeInput
                                selectedDate={portCallEtaResult?.reportingEta}
                                utc
                                onDatePickerClose={() => {}}
                                uneditableDatePicker
                            />
                        </Box>
                    </>
                ) : (
                    <Typography
                        component="span"
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            mt: "12px",
                        }}
                    >
                        <Typography variant="body2">
                            {bcDateTimeUTCFormat(
                                dayjs(portCallEtaResult?.reportingEta).utc(),
                                NEW_DATE_FORMAT,
                                "Not provided"
                            )}
                        </Typography>
                    </Typography>
                )}
            </>
        )
    }, [editMode, portCallEtaResult?.reportingEta]);

    return (
        <>
            {
                isNeedToShow && (
                    <Box
                        sx={{
                            mb: "8px",
                        }}
                     >
                        {reportingEtaContent}
                    </Box>
                )
            }
        </>
    )
}

export default ReportingEtaField
import { useCallback, useContext, useMemo, useState } from "preact/hooks";
import { AuthContext } from "../context/AuthContext";
import { API_METHODS, BASE_URL, isDevelop } from "../utils/constants/api";
import { getPostRequestCount, addPostRequestToStore } from "../db";
import { useServiceWorkerContext } from "../context/ServiceWorkerContext";
import { useToastState } from "./useToastState";
import Alert from "../mui/components/alert/Alert";
import jwtDecode from "jwt-decode";

const checkIsTokenExistAndValid = (token) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  const expirationTime = decodedToken.exp ? decodedToken.exp * 1000 : 0;
  const currentTime = Date.now();

  const expiresIn = expirationTime - currentTime;
  return expiresIn > 0;
};

export const useHttp = (setEditMode, setIsAnyPortcallInEditMode) => {
  const [isLoading, setIsLoading] = useState(false);
  const { token, logout, setAutoLoggedOutBecauseOfToken } =
    useContext(AuthContext);
  const { updateCount } = useServiceWorkerContext();

  const { showSuccessToast, isToastShown, closeToast } = useToastState();

  const request = useCallback(
    async (
      url,
      method = API_METHODS.GET,
      payload = null,
      successCallback,
      errCallback,
      patchDataOnOfflineMode,
      headersFromRequest
    ) => {
      const headers = headersFromRequest ? headersFromRequest : {};
      let body = {};
      if (method === API_METHODS.GET) {
        body = null;
      }
      try {
        if (payload) {
          body = JSON.stringify({ ...payload });
          if (!headersFromRequest) {
            headers["Content-Type"] = "application/json";
          }
        }
        if (!headersFromRequest) {
          headers["Authorization"] = `Bearer ${token}`;
        }
        isDevelop() &&
          console.log({ url: BASE_URL + url, method, payload, body, headers });
        if (!navigator.onLine) {
          if (method === API_METHODS.GET) {
            return;
          }
          await addPostRequestToStore({
            url: BASE_URL + url,
            method,
            payload,
            body,
            headers,
          });
          patchDataOnOfflineMode && patchDataOnOfflineMode();
          console.log(
            { url: BASE_URL + url, method, payload, body, headers },
            "console"
          );
          showSuccessToast(
            "Your request was not sent because you are offline. The request has been added to the queue to be sent"
          );
          if (setEditMode && setIsAnyPortcallInEditMode) {
            setEditMode(false);
            setIsAnyPortcallInEditMode(false);
          }
          const count = await getPostRequestCount();
          updateCount(count);
          setIsLoading(false);
          return;
        }
        if (!checkIsTokenExistAndValid(token)) {
          setAutoLoggedOutBecauseOfToken(true);
          logout();
          return;
        }
        setIsLoading(true);
        const response = await fetch(BASE_URL + url, { method, body, headers });
        await fetch(BASE_URL + "SetUserLastActive", {
          method: "POST",
          headers,
        });
        if (!response.ok) {
          if (response.status === 500 && method === API_METHODS.GET) {
            logout();
          }
          const data = await response.text();
          isDevelop() && console.log({ error: data, response });
          errCallback(data);
          setIsLoading(false);
          return;
        }
        let data;
        if (method === API_METHODS.GET) {
          data = await response.json();
        } else {
          data = await response.text();
        }

        isDevelop() && console.log(`response data - ${url}`, data);
        successCallback(data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        isDevelop() && console.log("Error", e);
        errCallback(e);

        throw e;
      }
    },
    []
  );

  const toaster = useMemo(() => {
    return (
      <Alert
        severity="warning"
        message={
          isToastShown
            ? "Updated added to the queue for when next online"
            : null
        }
        onClose={closeToast}
      />
    );
  }, [isToastShown, closeToast]);

  return { isLoading, request, toaster };
};

import { lightColors } from "../../utils/lightColors";
import { darken } from "@mui/material/styles";
import { darkColors } from "../../utils/darkColors";

export const selectPalette = (mode) => {
  if (mode === "dark") {
    return {
      outlined: {
        border: lightColors.primary["main-75"],
        color: lightColors.colors.white,
        focus: darkColors.primary.main,
        background: darkColors.dark.main,
      },
    };
  }

  return {
    outlined: {
      border: lightColors.primary["main-75"],
      color: lightColors.dark.main,
      focus: lightColors.primary.main,
      background: lightColors.colors.white,
    },
  };
};

export const selectConfig = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiSelect-select": {
        borderRadius: `6px`,
        color: theme.palette.components.select.outlined.color,
        backgroundColor: theme.palette.components.select.outlined.background,
        "& .MuiSelect-select": {
          color: theme.palette.components.select.outlined.color,
        },
        "& fieldset": {
          borderColor: theme.palette.components.select.outlined.border,
        },
        "&:hover fieldset": {
          borderColor: darken(
            theme.palette.components.select.outlined.border,
            0.1
          ),
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.components.select.outlined.focus,
        },
      },
    }),
  },
};

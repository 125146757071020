import { Dayjs } from "dayjs";

import { changeDateMonth } from "../../utils/helpers/dateTimePickerHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { BcButton } from "../globalComponents/button/BcButton";
import { SECONDARY_STYLE } from "../../utils/constants/styleConstants";
import { Box, IconButton, Stack } from "@mui/material";
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  CachedRounded,
} from "@mui/icons-material";

export interface IDatePickerSelectorProps {
  shownDate: Dayjs;

  setShownDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  resetDatePicker: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDatePickerResetable: boolean;
}

export const DatePickerSelector = ({
  shownDate,
  setShownDate,
  resetDatePicker,
  isDatePickerResetable,
}: IDatePickerSelectorProps) => {
  const handleIconClick = (isNextMonth: boolean) => {
    return () => {
      setShownDate(changeDateMonth(shownDate, isNextMonth));
    };
  };

  return (
    <Stack
      sx={{
        marginBottom: "4px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "16px",
        }}
      >
        {shownDate.format("MMM YYYY")}
      </Box>

      <Stack sx={{ flexDirection: "row", marginLeft: "auto" }} gap="8px">
        {isDatePickerResetable ? (
          <IconButton onClick={resetDatePicker}>
            <CachedRounded />
          </IconButton>
        ) : null}
        <IconButton variations="text" onClick={handleIconClick(false)}>
          <ArrowBackRounded />
        </IconButton>
        <IconButton variations="text" onClick={handleIconClick(true)}>
          <ArrowForwardRounded />
        </IconButton>
      </Stack>
    </Stack>
  );
};

import { useCallback, useState } from "preact/hooks";

export const useToastState = () => {
  const [isToastShown, setIsToastShown] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [isFailedToast, setIsFailedToast] = useState(false);
  const [toastBodyContent, setToastBodyContent] = useState(null);

  const showSuccessToast = useCallback(
    (toastBodyContent) => {
      setIsFailedToast(false);
      setToastBodyContent(toastBodyContent);
      setIsSuccessToast(true);
      setIsToastShown(true);
    },
    [setIsSuccessToast, setIsFailedToast, setIsToastShown, setToastBodyContent]
  );
  const showFailedToast = useCallback(
    (toastBodyContent) => {
      setIsSuccessToast(false);
      setToastBodyContent(toastBodyContent);
      setIsFailedToast(true);
      setIsToastShown(true);
    },
    [setIsSuccessToast, setIsFailedToast, setIsToastShown, setToastBodyContent]
  );
  const closeToast = useCallback(() => {
    setIsSuccessToast(false);
    setIsFailedToast(false);
    setIsToastShown(false);
    setToastBodyContent(null);
  }, [
    setIsSuccessToast,
    setIsFailedToast,
    setIsToastShown,
    setToastBodyContent,
  ]);

  return {
    isToastShown,
    isSuccessToast,
    isFailedToast,
    showSuccessToast,
    showFailedToast,
    closeToast,
    toastBodyContent,
  };
};

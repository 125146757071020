import { getInitialEmptyFactors } from "../BunkersLiftedBDNFiguresField/useBDNFiguresFieldFunctionality";
import { useGlobalDataContext } from "../../../../../context/GlobalDataContextProvider";
import { useState } from "preact/hooks";
import { areEqual } from "../../../../../utils/helpers/globalHelpers";
import { API_METHODS } from "../../../../../utils/constants/api";
import { BunkerFactorType } from "../../../../../utils/enums";
import { useEffect } from "react";
import { updateBunkerFactorsSettings } from "../../../../../db";

export const useBunkersSubmitFunctionality = ({
  liftedBunkerProductsInPort,
  request,
  setLiftedBunkerProductField,
  setErrors,
}) => {
  const { bunkerFactorSettings, refillBunkerFactorsSettingsFromStore } =
    useGlobalDataContext();
  const getInitialBunkerFactors = () => {
    return liftedBunkerProductsInPort.map((singleBunkerProduct) => {
      const currentBunkerFactorsSettings = bunkerFactorSettings?.find(
        (singleBunkerFactorSetting) =>
          singleBunkerFactorSetting.productType === singleBunkerProduct.type
      );
      const initialFactorValues = getInitialEmptyFactors(
        currentBunkerFactorsSettings?.factorValues,
        singleBunkerProduct.bunkerFactors
      );
      return {
        id: currentBunkerFactorsSettings?.id,
        productType: singleBunkerProduct.type,
        bunkerFactors: initialFactorValues,
        bdnFiguresIsExist: singleBunkerProduct?.quantity,
      };
    });
  };

  const [initialBunkerFactorsValues] = useState(getInitialBunkerFactors());
  const [bunkerFactorsValues, setBunkerFactorValues] = useState(
    initialBunkerFactorsValues
  );

  const setInitialBunkerFactorsState = () => {
    setBunkerFactorValues(initialBunkerFactorsValues);
  };

  const isChanged = !areEqual(bunkerFactorsValues, initialBunkerFactorsValues);

  useEffect(() => {
    if (bunkerFactorsValues.length === 0) {
      setInitialBunkerFactorsState();
    }
  }, [bunkerFactorSettings, initialBunkerFactorsValues]);

  const updateBunkers = () => {
    if (isChanged) {
      bunkerFactorsValues.forEach((singleBunkerProduct) => {
        const updatedFactors = singleBunkerProduct.bunkerFactors.map(
          (singleBunkerFactor) => ({
            bunkerFactorType: singleBunkerFactor.type,
            value: singleBunkerFactor.value
              ? Number(singleBunkerFactor.value)
              : null,
          })
        );

        const emmissionsFilter = (singleBunkerFactor) =>
          [
            BunkerFactorType.EmissionConversionEuMrv,
            BunkerFactorType.EmissionConversionImoDcs,
          ].includes(singleBunkerFactor.type);

        setLiftedBunkerProductField(
          singleBunkerProduct.productType,
          "bunkerFactors",
          updatedFactors
        );
        const initialSingleBunkerProduct = initialBunkerFactorsValues.find(
          (singleBunkerProductInInitial) =>
            singleBunkerProductInInitial.productType ===
            singleBunkerProduct.productType
        );

        const currentBunkerFactors =
          singleBunkerProduct.bunkerFactors.filter(emmissionsFilter);
        const initialBunkerFactors =
          initialSingleBunkerProduct.bunkerFactors.filter(emmissionsFilter);

        if (!areEqual(currentBunkerFactors, initialBunkerFactors)) {
          request(
            "UpdatePortCallOrganizationBunkerFactorSetting",
            API_METHODS.PUT,
            {
              id: singleBunkerProduct?.id ?? initialSingleBunkerProduct?.id,
              factorValues:
                singleBunkerProduct.bunkerFactors.filter(emmissionsFilter),
            },
            () => {
              updateBunkerFactorsSettings(
                updatedFactors,
                singleBunkerProduct.id,
                refillBunkerFactorsSettingsFromStore
              );
            },
            () => {},
            () => {
              updateBunkerFactorsSettings(
                updatedFactors,
                singleBunkerProduct.id,
                refillBunkerFactorsSettingsFromStore
              );
              localStorage.setItem(
                "bunkerFactorsSettings",
                JSON.stringify(
                  bunkerFactorSettings.map((singleBunkerFactorSetting) => {
                    return singleBunkerFactorSetting.id ===
                      singleBunkerProduct.id
                      ? {
                          ...singleBunkerFactorSetting,
                          factorValues: updatedFactors,
                        }
                      : singleBunkerFactorSetting;
                  })
                )
              );
            }
          );
        }
      });
    }
  };
  useEffect(() => {
    navigator.onLine && localStorage.removeItem("bunkerFactorsSettings");
  }, [navigator.onLine]);

  return {
    bunkerFactorsValues,
    setBunkerFactorValues,
    isChanged,
    bunkerFactorSettings,
    initialBunkerFactorsValues,
    updateBunkers,
    resetBunkerFactors: setInitialBunkerFactorsState,
  };
};

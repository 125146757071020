import { useCallback, useEffect, useState } from "preact/hooks";
import { Loader } from "../../components/loader/Loader";
import { ArchiveContainer } from "../../containers/archive/ArchiveContainer";
import { useHttp } from "../../hooks/useHttp";
import { useServiceWorkerContext } from "../../context/ServiceWorkerContext";
import {
  addPortcallsToStore,
  ARCHIVE_PORTCALLS,
  deleteAllFromArchivePortcalls,
  getAllFromArchivePortcalls,
} from "../../db";
import Alert from "../../mui/components/alert/Alert";
import Stack from "@mui/material/Stack";

const Archive = () => {
  const [archivePortcalls, setArchivePortcalls] = useState([]);
  const [archiveTotalCount, setArchiveTotalCount] = useState(0);
  const [baseUrlToMainApp, setBaseUrlToMainApp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errMessage, setErrMessage] = useState(null);
  const {
    state: { isOnline, count },
  } = useServiceWorkerContext();
  const { request } = useHttp();

  const getCachePortcalls = useCallback(async () => {
    return await getAllFromArchivePortcalls();
  }, []);

  const getArchivedPortcalls = async () => {
    if (isOnline) {
      if (count > 0) return;
      setIsLoading(true);
      request(
        `GetArchivedVoyages?startRow=${archivePortcalls.length}`,
        "GET",
        null,
        async (data) => {
          setArchivePortcalls([...archivePortcalls, ...(data?.voyages ?? [])]);
          setArchiveTotalCount(data.totalCount);
          const cachePortcalls = (data?.portCalls ?? []).slice(0, 6);
          await deleteAllFromArchivePortcalls();
          await addPortcallsToStore(cachePortcalls, ARCHIVE_PORTCALLS);
          setBaseUrlToMainApp(data.baseUrl);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          setErrMessage(`${err}; Try to logout/login`);
        }
      );
    } else {
      const cachedData = await getCachePortcalls();
      setArchivePortcalls(cachedData[0]);
    }
  };
  useEffect(() => {
    getArchivedPortcalls();
  }, [isOnline, count]);

  if (isLoading && !archivePortcalls?.length)
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <Loader displayText />
      </Stack>
    );

  const containerKey = archivePortcalls
    ?.map(({ portCallId }) => portCallId)
    ?.join("/");

  return (
    <div>
      <Alert message={errMessage} severity="error" onClose={() => setErrMessage(null)} />
      {archivePortcalls.length ? (
        <ArchiveContainer
          key={containerKey}
          archivePortcalls={archivePortcalls}
          archiveTotalCount={archiveTotalCount}
          getArchivedPortcalls={getArchivedPortcalls}
          isLoading={isLoading}
          baseUrlToMainApp={baseUrlToMainApp}
          setArchivePortcalls={setArchivePortcalls}
        />
      ) : (
        "No archived portcalls"
      )}
    </div>
  );
};
export default Archive;

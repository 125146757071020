import {ThemeProvider} from "@mui/material";
import useMuiTheme from "./useMuiTheme";

const MuiProvider = ({ children, mode }) => {
    const theme = useMuiTheme(mode);
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    );
};

export default MuiProvider;
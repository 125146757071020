import {darkColors} from "../../mui/utils/darkColors";
import {lightColors} from "../../mui/utils/lightColors";

export const portcallsPalette = (mode) => {
    if(mode === "dark") {
        return {
            voyageNoTitle: darkColors.colors.tranquilTide,
            portcallsCount: {
                background: darkColors.dark["main-25"],
                color: darkColors.colors.white
            }
        }
    }

    return {
       voyageNoTitle: lightColors.secondary.main,
        portcallsCount: {
            background: lightColors.primary["main-25"],
            color: lightColors.secondary.main
        }
    }
}
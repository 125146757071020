import {lightColors} from "../../../mui/utils/lightColors";
import {darkColors} from "../../../mui/utils/darkColors";

export const themeTogglePalette = (mode) => {
    if(mode === "dark") {
        return  {
            color: darkColors.colors.white,
            background: "transparent",
        }
    }

    return {
        color: lightColors.secondary.main,
        background: "transparent",
    }
}
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <Box width="100%">
      <Stack
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={"20px"}
        py="30px"
      >
        <Typography variant="body2" component="span">
          © 2024
        </Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{
            "& a": {
              textDecoration: "none",
              color: "components.typography.body2.color",
            },
          }}
        >
          <a
            href="https://www.beacon52.com/legal/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{
            "& a": {
              textDecoration: "none",
              color: "components.typography.body2.color",
            },
          }}
        >
          <a
            href="https://www.beacon52.com/legal/cookie-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Cookie Policy
          </a>
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;

export const NO_BILL_OF_LADING_STATUS = "No Bill of Lading Status";

export const billOfLadingStatusEnums = [
  { value: 10000, label: NO_BILL_OF_LADING_STATUS },
  { value: 1000, label: "Original B/L on board" },
  { value: 2000, label: "Original B/L not on board" },
  { value: 3000, label: "LOI received" },
  { value: 4000, label: "LOI invokement received" },
  { value: 6000, label: "Electronic B/L" },
  { value: 7000, label: "Seawaybill (SWB)" },
  { value: 8000, label: "Mate’s receipt" },
];

export const earlyDelayArrivalReason = [
  { value: 1000, label: "Berth Congestion" },
  { value: 2000, label: "Ice" },
  { value: 3000, label: "In Competition For Berth" },
  { value: 4000, label: "Laycan" },
  { value: 5000, label: "Other" },
  { value: 6000, label: "Rest Hours" },
  { value: 7000, label: "Tank Cleaning And Preparation" },
  { value: 8000, label: "Traffic" },
  { value: 9000, label: "Weather" },
];

export const cargoActivityEnums = [
  { value: 1000, label: "Loading" },
  { value: 2000, label: "Discharging" },
];

export const fuelTypesEnums = [
  { value: 500, label: "380" },
  { value: 1000, label: "HSFO" },
  { value: 1250, label: "IFO" },
  { value: 1375, label: "LCF" },
  { value: 1400, label: "LCG" },
  { value: 1425, label: "LCM" },
  { value: 1500, label: "LNG" },
  { value: 1625, label: "LSO" },
  { value: 1750, label: "MDO" },
  { value: 2000, label: "MGO" },
  { value: 3000, label: "ULSFO" },
  { value: 4000, label: "VLSFO" },
];

export const tugsOrderedReasonEnums = [
  { value: 1000, label: "Compulsory" },
  { value: 2000, label: "Master" },
  { value: 3000, label: "Technical" },
];

export const tugsSubReasonEnums = [
  { value: 100, label: "Berth Conditions" },
  { value: 200, label: "Current Tide" },
  { value: 300, label: "Ice" },
  { value: 400, label: "Weather" },
];

export const quantityMetricEnums = [
  { value: 1000, label: "CBM" },
  { value: 2000, label: "LMT" },
  { value: 3000, label: "LT" },
  { value: 4000, label: "Meters" },
  { value: 5000, label: "MT" },
  { value: 6000, label: "ST" },
  { value: 7000, label: "SM" },
  { value: 8000, label: "TEU" },
  { value: 9000, label: "US Barrels" },
];

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
const ResetPasswordSuccess = ({setScreen}) => {

    const goToSignIn = () => {
        setScreen('sign-in')
    }

    return <Stack width='100%' flexDirection="column" justifyContent='center' alignItems='flex-start'>
        <Typography sx={{fontWeight: 500, fontSize: '24px', lineHeight: '29.05px', textAlign: 'left', color: 'layout.login.title', marginBottom: '34px'}} variant="h4" component="h4">
            Set your password
        </Typography>
        <Typography sx={{fontWeight: 400, fontSize: '20px', lineHeight: '24.2px', textAlign: 'left', color: 'layout.login.text', marginBottom: '10px'}} variant="h5" component="h5">
            Thank You!
        </Typography>
        <Typography sx={{fontWeight: 400, fontSize: '14px', lineHeight: '21px', textAlign: 'left', color: 'layout.login.text', marginBottom: '35px'}} variant="body1" component="body1">
            Your password has been reset. You can now log in with your new password
        </Typography>
        <Button type='submit' variant='contained' fullWidth onClick={goToSignIn} sx={{padding: '13px 20px', maxHeight: '45px'}}>Back to login</Button>
    </Stack>
}

export default ResetPasswordSuccess;
import { BcInput } from "../../../globalComponents/input/BcInput";
import { Box, Stack, Typography } from "@mui/material";

const FreshWater = ({
  isArrival,
  editMode,
  freshWater,
  setFreshWater,
  isLoading,
}) => {
  return (
    <Stack alignItems="start" mb="16px">
      {!editMode && (
        <Typography
          variant="body1"
          sx={{
            marginBottom: "4px",
          }}
        >
          Fresh Water
        </Typography>
      )}

      {editMode ? (
        <BcInput
          type="text"
          value={freshWater}
          label={"Fresh Water"}
          onInput={(value) => {
            const formattedTargetValue = value
              .replace(/[^0-9.,]/g, "")
              .replace(/,/g, ".");
            setFreshWater(formattedTargetValue, isArrival);
          }}
          sx={{
            width: "236px",
            mt: "15px",
            textAlign: "end",
          }}
          isLoading={isLoading}
        />
      ) : (
        <Typography variant="body2" mt="6px">
          {freshWater ?? "-"}
        </Typography>
      )}
    </Stack>
  );
};

export default FreshWater;
